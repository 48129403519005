<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4" > 
  <!-- Brand Logo -->
  <a href="" class="brand-link">
    <img src="assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
         style="opacity: .8">
    <span class="brand-text font-weight-light">CMS</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
  
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column " id="sideBar" data-widget="treeview" role="menu" data-accordion="true">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/dashboard']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            Dashboard
          </a>
        </li>
        <li class="nav-item" *ngIf="userPermissions.includes('medialibrary') || userRoles.includes('SibinAdmin')">
          <a class="nav-link" [routerLink]="['/media-library']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <i class="nav-icon fas fa-images"></i>
            Media Library
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="userPermissions.includes('masterconfiguration') || userRoles.includes('SibinAdmin')">
          <a class="nav-link child" style="cursor:pointer">
            <i class="nav-icon  fas fa-cogs"></i>

            Master Configuration
            <i class="fas fa-angle-left right"></i>

          </a>
          <ul class="nav  nav-treeview ml-3">
            <li class="nav-item" *ngIf="userPermissions.includes('managemenus') || userRoles.includes('SibinAdmin')">
              <a [routerLink]="['/master-configuration/menu']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-th-list nav-icon"></i>
                Menu
              </a>
            </li>
            <li class="nav-item" *ngIf="userPermissions.includes('managecategories') || userRoles.includes('SibinAdmin')">
              <a [routerLink]="['/master-configuration/category']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-list-alt nav-icon"></i>
                Category
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item has-treeview" *ngIf="userPermissions.includes('usermanagement') || userRoles.includes('SibinAdmin')">
          <a class="nav-link" style="cursor:pointer">
            <i class="nav-icon  fas fa-users"></i>
            User Management
            <i class="fas fa-angle-left right"></i>

          </a>
          <ul class="nav  nav-treeview ml-3">
            <li class="nav-item" *ngIf="userPermissions.includes('manageroles') || userRoles.includes('SibinAdmin')">
              <a [routerLink]="['/roles']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-th-list nav-icon"></i>
                Roles
              </a>
            </li>
            <li class="nav-item" *ngIf="userPermissions.includes('manageusers') || userRoles.includes('SibinAdmin')">
              <a [routerLink]="['/users']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-users nav-icon"></i>
                Users
              </a>
            </li>
            <li class="nav-item" *ngIf="userPermissions.includes('managemodules') || userRoles.includes('SibinAdmin')">
              <a [routerLink]="['/modules']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-cog nav-icon"></i>
                Modules & Permissions
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview" *ngFor="let menu of MenuList">
          <span *ngIf="userPermissions.includes('manageposts') || userRoles.includes('SibinAdmin')">
            <a *ngIf="!menu.submenu && menu.routinglink!='external'" [routerLink]="['/post/',menu.id]" class="nav-link" routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: true }">
              <i class="nav-icon far fa-edit"></i>

              {{menu.title}}
            </a>
            <a *ngIf="menu.submenu && menu.routinglink!='external'" class="nav-link"
               routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              <i class="nav-icon fas fa-th-list "></i>

              {{menu.title}}
              <i class="fas fa-angle-left right" style="top:auto"></i>

            </a>
            <ul class="nav  nav-treeview ml-3" *ngIf="menu.routinglink!='external'">
              <li class="nav-item" *ngFor="let subMenu of subMenuList">
                <a *ngIf="menu.id==subMenu.parentId" [routerLink]="['/post/',subMenu.id]" class="nav-link"
                   routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <i class="far fa-edit nav-icon"></i>
                  {{subMenu.title}}
                </a>
              </li>
            </ul>

          </span>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
