 import { Injectable } from '@angular/core';
    import { Observable, Subject } from 'rxjs';
    
    @Injectable({ providedIn: 'root' })
    export class CommonService {
        private subjectName = new Subject<any>();
    
      sendLatestMenu(data) { 
            this.subjectName.next(data); 
        }
    
      getLatestMenu(): Observable<any> {
            return this.subjectName.asObservable(); 
        }
    }
