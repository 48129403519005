import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.css']
})
export class AlbumComponent implements OnInit {
  newAlbum: any = {};
    apiUrl: string;
  id: any;
  imageurl :string;
    coverimage: string | ArrayBuffer;
    userRoles: any;
    userPermissions: any;
  constructor(private http: HttpClient, private datePipe: DatePipe,
    private router: Router, private _activeRoute: ActivatedRoute,private appComponent: AppComponent) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state != undefined) {
      const data = navigation.extras.state as {
        details: any

      };
      this.newAlbum = data.details;
    }

  }

  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    this.apiUrl = this.appComponent.APIURL;
    this.imageurl= this.appComponent.IMAGEURL;
    this.id = localStorage.getItem('id');
    debugger;
    if (this.id != null) {
      this.getAllAlbums(this.id);
      localStorage.removeItem('id');
    }
  }
  ClearAlbum() {
    this.newAlbum = {};
  }
  getAllAlbums(id) {
    this.http.get(this.apiUrl + 'albummaster/'+id, {
    }).subscribe((data: any) => {
      this.newAlbum = data.albumDTO[0];
      this.appComponent.stopSpinner();
    });
  }
  fileChangeEvent(event: any): void {
    let valid = [
      "webp", "jpg", "jpeg", "png"
    ];
    if (event.target.files.length > 0) {
      var files = event.target.files;
      let fileExt = files[0].name.split('.').pop();
        if (valid.includes(fileExt.toLowerCase())) {
          this.newAlbum.image = files[0];

          var reader = new FileReader();

          reader.readAsDataURL(files[0]); // read file as data url

          reader.onload = (event) => { // called once readAsDataURL is completed
            this.coverimage = reader.result;
          }
        }
        else {
         alert("Please select valid file(webp,png,jpg or jpeg)")
        }
      
    }
  }

  saveAlbum(albumData) {
    let formData = new FormData();
    formData.append('Files', this.newAlbum.image);
    formData.append('albumname', albumData.albumname);
    albumData.coverimage == undefined ? null : formData.append('coverimage', albumData.coverimage);
    let url;
    if (!albumData.id) {
      url = this.apiUrl + 'albummaster';
    }
    else {
      formData.append('id', albumData.id)
      url = this.apiUrl + 'albummaster/' + albumData.id;
    }
    this.http.post(url,formData,)
      .subscribe((data: any) => {
        if (!data.isSucceeded) {
          alert(data.message);
        }
        else {
          let menuId = localStorage.getItem('menuId');
          this.router.navigate(['/post/create/' + menuId]);
        }

      });
  }

}
