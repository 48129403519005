<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Main content -->
  <section class="content pt-3">

    <div class="row"  style=" height: 535px;">
      <div class="col-md-12">
        <div class="card card-outline card-info">
          <!-- /.card-header -->
          <div class="card-body pad">
            <form role="form" #menuForm ngNativeValidate>
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h4>Add Menu</h4>
                    </div>
                    <div class="col-sm-6">
                      <button class="btn btn-primary btn-sm mr-1 float-right" (click)="menuForm.checkValidity()?SaveMenuDetails(newMenu):null"
                              *ngIf="userPermissions.includes('createmenu') || userPermissions.includes('editmenu') || userRoles.includes('SibinAdmin')">Save</button>
                      <button class="btn btn-primary btn-sm mr-1 float-right" (click)="ClearMenuDetails()">Clear</button>
                    </div>
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <!-- text input -->

                </div>
              </div>
              <div class="row" >
                <div class="col-sm-4">
                  <div class="form-group">
                    <input type="hidden" class="form-control" required id="id" name="id" [(ngModel)]="newMenu.id" [ngModelOptions]="{standalone: true}"
                           #id="ngModel">
                    <label>Menu Type </label>
                    <span><strong style="font-size: 20px;color : red;">*</strong></span>
                    <select name="type" [(ngModel)]="MenuType"
                            [ngModelOptions]="{standalone: true}" #state="ngModel" id="type" class="form-control" required>
                      <option value="">--Select--</option>
                      <option value="mainMenu">Main Menu</option>
                      <option value="subMenu">Sub Menu</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Title</label>
                    <span><strong style="font-size: 20px;color : red;">*</strong></span>

                    <input type="text" class="form-control" required id="title" name="title" [(ngModel)]="newMenu.title" [ngModelOptions]="{standalone: true}"
                           #title="ngModel">
                  </div>
                </div>
                <div class="col-sm-4" *ngIf="MenuType == 'subMenu'">
                  <div class="form-group">
                    <label>Parent Menu Name</label>
                    <span><strong style="font-size: 20px;color : red;">*</strong></span>
                    <select name="parentId" [(ngModel)]="newMenu.parentId" required
                            [ngModelOptions]="{standalone: true}" id="parentId" class="form-control">
                      <option [ngValue]="selected">--Select Parent Menu--</option>
                      <option *ngFor="let menu of menuList" value="{{menu.id}}">{{menu.title}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label _ngcontent-ng-cli-universal-c9="">
                      <strong _ngcontent-ng-cli-universal-c9="">Routing Link</strong>
                      (<input type="radio" class="form-group ml-1 mr-1" [(ngModel)]="newMenu.routinglink"
                              name="routinglink" style=" margin-bottom: 5px;" value="internal" id="internal">internal
                      <input type="radio" class="form-group ml-1 mr-1 mt-1" name="routinglink" [(ngModel)]="newMenu.routinglink"
                             style="margin-bottom: 5px;" value="external" id="external">external)
                    </label>
                    <select class="form-control" id="link" name="link" [(ngModel)]="newMenu.link" [ngModelOptions]="{standalone: true}"
                            #link="ngModel" *ngIf="newMenu.routinglink=='internal'">
                      <option [ngValue]="selected">--Select Routing Link--</option>
                      <option *ngFor="let item of allRoute" value="{{item.link}}">{{item.link}}
                      </option>

                    </select>
                    <a class="text-info cursor-pointer ml-2 mt-2" data-toggle="modal" data-target="#addModal" title="Add New"
                       *ngIf="newMenu.routinglink=='internal' && (userPermissions.includes('manageroutingpath') || userRoles.includes('SibinAdmin'))">
                      Add New
                    </a>
                    <input type="text" class="form-control" id="link" name="link" [(ngModel)]="newMenu.link" [ngModelOptions]="{standalone: true}"
                           #link="ngModel" *ngIf="newMenu.routinglink=='external'" placeholder="www.example.com">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label><strong>Menu Options</strong></label>
                    <div class="row">
                      <div class="col-sm-4">

                        <input type="checkbox" id="post" name="post" [(ngModel)]="newMenu.post"
                               #post="ngModel" data-off-color="danger">
                        <label class="ml-1"> Post</label>

                      </div>
                      <div class="col-sm-4">
                        <input type="checkbox" id="blog" name="blog" [(ngModel)]="newMenu.blog"
                               #blog="ngModel" data-off-color="danger">
                        <label class="ml-1"> Blog</label>

                      </div>
                      <div class="col-sm-4">

                        <input type="checkbox" id="gallery" name="gallery" [(ngModel)]="newMenu.gallery"
                               #gallery="ngModel">
                        <label class="ml-1"> Gallery</label>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4"  style="margin-top: 36px;">
                  <div class="row">
                    <div class="col-sm-6">
                      <!-- text input -->
                      <div class="form-group">
                        <input type="checkbox" [(ngModel)]="newMenu.multipleEntry" id="multipleEntry" name="multipleEntry" >
                        <label class="ml-1">Multiple Entry</label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <!-- text input -->
                      <div class="form-group">
                        <input type="checkbox" [(ngModel)]="newMenu.displayonhome" id="displayonhome" name="displayonhome">
                        <label class="ml-1">Display on Home</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <!-- /.col-->
    </div>
    <!-- ./row -->
  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->

<div id="addModal" class="modal">
  <div class="modal-dialog modal-confirm">
    <form role="form" #routingForm ngNativeValidate>
      <div class="modal-content" style="text-align:left">
        <div class="modal-header flex-column border-bottom-1">
          <h6 class=" w-100">Please refer routing path of website.</h6>
        </div>

        <div class="modal-body">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Routing Path</label>
              <span><strong style="font-size: 20px;color : red;">*</strong></span>

              <input type="text" class="form-control" required id="routingpath" name="routingpath" [(ngModel)]="newRoutingPath.routingpath" [ngModelOptions]="{standalone: true}"
                     #routingpath="ngModel" placeholder="pathName">
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button class="btn btn-success" (click)="routingForm.checkValidity()?saveRoutingPath(newRoutingPath):null" > Save </button>
        </div>
      </div>
      </form>
  </div>
</div>
