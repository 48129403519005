import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from '../Services/common.service';
import { AuthenticationService } from '../services/authentication.service';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
  @Injectable()
export class SidebarComponent implements OnInit {
  apiUrl: string;
  MenuList: any;
  role: any = localStorage.getItem('roleName');
  private subscriptionName: Subscription;
    messageReceived: any;
    subMenuList: any;
    userRoles: any;
    userPermissions: any;
  constructor(private _activeRoute: ActivatedRoute,
    private http: HttpClient, private Service: CommonService,
    private router: Router, private appComponent: AppComponent,
    private authService: AuthenticationService)
  {
    this.subscriptionName = this.Service.getLatestMenu().subscribe
      (data => { 
        this.MenuList = data.filter(x => x.status == 'Active' && x.parentId == 0);
        this.subMenuList = data.filter(x => x.status == 'Active' && x.parentId != 0);
      });
  
  }

  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    $('[data-widget="treeview"]').Treeview('init');
    this.getAllMenuList();
  }
  getAllMenuList() {
    this.apiUrl = this.appComponent.APIURL;
    let id = 0;
    //this.appComponent.startSpinner();
    this.http.get(this.apiUrl + 'MenuMaster/'+id, {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.status == 'Active' && x.parentId == 0);
      this.subMenuList = data.menuMasterDTO.filter(x => x.status == 'Active' && x.parentId != 0);
    }
      , (err) => {
        //this.appComponent.stopSpinner();
      });
  }
}
