import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent, ImageTransform, Dimensions, base64ToFile } from 'ngx-image-cropper';
import { DatePipe } from '@angular/common';
import { AppComponent } from '../../app.component';
declare var $: any;
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {
  postDetails: any = {}
  apiUrl: string;
  files: any;
  menuList: any;
  categoryList: any;
  aspectRatio: any;
  _imagesPrev: any = [];
  imageFile: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  _files: any = [];
  menuId: any;
  selectedFileId: number = 0;
  imageurl: string;
  imageurlMedium: string;
  albumList: any;
  postId: string;
  blog: string[];
  menuName: any;
  downloadFileUrl: string;
  imageext: any;
  fileext: any;
  requiredFields: any = [];
  media: any;
  enableOk: boolean = true;
  mediaIds: any = [];
    images: any=[];
    renameValue: any;
    mediaId: any;
    userRoles: any;
  userPermissions: any;
  Search: any;
  newMedia: any;
    showMediaModel: boolean;
  constructor(private http: HttpClient, private datePipe: DatePipe,
    private router: Router, private _activeRoute: ActivatedRoute, private appComponent: AppComponent) {

  }

  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    this.postDetails.albumtype = 'photo';
    this.postDetails.uploadtype = 'browse';
    this.postDetails.categoryId = "";
    this.postDetails.albumid = "";
    this.postId = localStorage.getItem("postId");
    this.apiUrl = this.appComponent.APIURL;
    this.imageurl = this.appComponent.IMAGEURL;
    this.imageurlMedium = this.appComponent.IMAGEURL;
    this.downloadFileUrl = this.appComponent.IMAGEURL;
    this._activeRoute.params.subscribe(
      params => {
        this.appComponent.startSpinner();
        this.menuId = params['id'];
        this.getMenu(this.menuId);
        this.getMedia(null);
        this.getCategoryByMenu(this.menuId);
        this.getAllAlbums();
        if (this.postId != null) {
          this.getPostDetails(this.postId)
        }
      })
    this.imageext = [
      "jpg", "jpeg", "png"
    ];
    this.fileext = [
      "xml", "pdf", "xlsx"
    ];
  }

  config = {
    placeholder: 'Content',
    tabsize: 2,
    height: 'auto',
    spellCheck: true,
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'clear', 'icon']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr', 'codeview', 'help']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }
  getMedia(status) {
    let $this = this;
    
    this.http.get(this.apiUrl + 'media', {
    }).subscribe((data: any) => {
      this.media = data.mediaDTO;
      console.log(this.media)
      this.media.forEach(function (item) {
        if (item.filepath.split(".")[1] == "pdf") {
          item.displayUrl = 'assets/img/icon-pdf.png';
        }
        else if (item.filepath.split(".")[1] == "xls" || item.filepath.split(".")[1] == "xlsx") {
          item.displayUrl = 'assets/img/icon-excel.jpg';
        }
        else {
          item.displayUrl = $this.imageurl + item.filepath;
        }
        if (status != null)
          $this.mediaIds.forEach(function (mId) {
            debugger;
            if (mId == item.id) {
              if ($this._files.length == 0) {
                $this._files.push({ photo: item.displayUrl, filepath: item.filepath, id: mId, caption: item.description });
              }
              else if ($this._files.filter(x => x.id == mId).length == 0) {
                $this._files.push({ photo: item.displayUrl, filepath: item.filepath, id: mId, caption: item.description });
              }
            }
              
          })
      })
      console.log("files", this._files)
      this.appComponent.stopSpinner();
    });
  }
  manageAlbums() {
    localStorage.setItem('menuId', this.menuId);
    this.router.navigate(['/master-configuration/album']);
  }
  addAlbum() {
    localStorage.setItem('menuId', this.menuId);
    this.router.navigate(['/master-configuration/album/create']);
  }
  getPostDetails(postId) {
    this.http.get(this.apiUrl + 'post/' + postId)
      .subscribe((data: any) => {
        let $this = this;
        this.postDetails = data.postDTO[0];
        this._files = this.postDetails.images == null ? [] : this.postDetails.images;
        //this.images = this.postDetails.images;
        this._files.forEach(function (item) {
          if (item.filepath.split(".")[1] == "pdf") {
            item.photo = 'assets/img/icon-pdf.png';
          }
          else if (item.filepath.split(".")[1] == "xls" || item.filepath.split(".")[1] == "xlsx") {
            item.photo = 'assets/img/icon-excel.jpg';
          }
          else {
            item.photo = $this.imageurl + item.filepath;
          }
        })
        this.postDetails.postdate = this.datePipe.transform(this.postDetails.postdate, 'yyyy-MM-dd');
        if (this.postDetails.image) {
          $("#file").removeAttr("required");
        }
        if (this.postDetails.categoryId == 0) {
          this.postDetails.categoryId = "";

        }
        if (this.postDetails.albumid == 0) {
          this.postDetails.albumid = "";
        }
        this.appComponent.stopSpinner();
      })
  }
  getAllAlbums() {
    this.http.get(this.apiUrl + 'albummaster', {
    }).subscribe((data: any) => {
      this.albumList = data.albumDTO.filter(x => x.status != "Archive");
    });
  }
  getMenu(menuId) {
    this.http.get(this.apiUrl + 'MenuMaster/' + menuId, {
    }).subscribe((data: any) => {
      this.menuList = data.menuMasterDTO[0];
      this.postDetails.menuId = this.menuList.id;
      this.menuName = this.menuList.title;
      this.getAspectRatio(this.menuList.id);
      if (this.menuList.post) {
        this.postDetails.type = 'post';
      }
      else if (this.menuList.blog) {
        this.postDetails.type = 'blog';
      }
      else if (this.menuList.gallery) {
        this.postDetails.type = 'gallery';
      }
      this.appComponent.stopSpinner();
    }, (err) => {
    });
  }
  getAspectRatio(id: any) {

    this.http.get(this.apiUrl + 'AspectRatioMaster/' + id, {
    }).subscribe((data: any) => {
      if (data.aspectRatioMasterDTO.length > 0)
        this.aspectRatio = data.aspectRatioMasterDTO[0].width / data.aspectRatioMasterDTO[0].height;
      this.getRequiredFields(id);
    }, (err) => {
    });

  }
  getRequiredFields(id: any) {
    this.http.get(this.apiUrl + 'RequiredFieldsMaster/' + id, {
    }).subscribe((data: any) => {

      if (data.requiredFieldDTO.length > 0)
        this.requiredFields = JSON.parse(data.requiredFieldDTO[0].fieldsname);
    }, (err) => {
    });
  }

  getCategoryByMenu(menuId) {
    this.http.get(this.apiUrl + 'CategoryMaster/getCetogeryByMenuId/' + menuId, {
    }).subscribe((data: any) => {
      this.categoryList = data.categoryMasterDTO.filter(x => x.status != "Archive");
    }, (err) => {
    });
  }

  ClearPost() {
    this.postDetails = {};
    this.postDetails.content = null;
    this._images = [];
    this.postDetails.categoryId = "";
    this.postDetails.albumid = "";
    this.getMenu(this.menuId);
    this.getCategoryByMenu(this.menuId);
  }


  savePost(postData) {
    debugger;
    let $this = this;
    let isValid = true;
    for (let j = 0; j < this.requiredFields.length; j++) {
      let value = $('#' + this.requiredFields[j]).val();

      if (this.requiredFields[j] == 'content') {
        if ($('#content').summernote('isEmpty')) {
          alert(this.requiredFields[j] + " is required. ");
          isValid = false;
        }
      }
      else if (this.requiredFields[j] == 'file') {
        if (!this.postDetails.image && !this._files) {
          alert(this.requiredFields[j] + " is required. ");
          isValid = false;
        }
      }
      else {
        if (!value) {
          alert(this.requiredFields[j] + " is required. ");
          isValid = false;
        }
      }

    }


    if (isValid) {
      //console.log(this.images);
      let formData = new FormData();
      this.appComponent.startSpinner();
      this.postDetails.uploadtype = this.postDetails.albumtype == 'photo' ? 'browse' : 'embeded';
      this.postDetails.title != undefined ? formData.append('title', this.postDetails.title) : null;
      this.postDetails.type != undefined ? formData.append('type', this.postDetails.type) : null;
      this.postDetails.categoryId != "" ? formData.append('categoryId', this.postDetails.categoryId) : null;
      this.postDetails.menuId != undefined ? formData.append('menuId', this.postDetails.menuId) : null;
     /* this.postDetails.image != undefined ?*/ formData.append('image', JSON.stringify(this._files)/*this.postDetails.imag*/)/* : null*/;
      this.postDetails.content != undefined ? formData.append('content', this.postDetails.content) : null;
      this.postDetails.shortDescription != undefined ? formData.append('shortDescription', this.postDetails.shortDescription) : null;
      this.postDetails.allowfollow != undefined ? formData.append('allowfollow', this.postDetails.allowfollow) : null;
      formData.append('status', "Active");
      this.postDetails.embdedUrl != undefined ? formData.append('embdedUrl', this.postDetails.embdedUrl) : null;
      this.postDetails.allowcomment != undefined ? formData.append('allowcomment', this.postDetails.allowcomment) : null;
      this.postDetails.allowlike != undefined ? formData.append('allowlike', this.postDetails.allowlike) : null;
      this.postDetails.author != undefined ? formData.append('author', this.postDetails.author) : null;
      formData.append('createdBy', localStorage.getItem('userid'));
      formData.append('UpdatedBy', localStorage.getItem('userid'));
      this.postDetails.displayonhome != undefined ? formData.append('displayonhome', this.postDetails.displayonhome) : null;
      this.postDetails.postdate != undefined ? formData.append('postdate', this.datePipe.transform(new Date(this.postDetails.postdate), 'yyyy-MM-dd')) : null;
      this.postDetails.albumid != '' ? formData.append('albumid', this.postDetails.albumid) : null;
      this.postDetails.albumtype != '' ? formData.append('albumtype', this.postDetails.albumtype) : null;
      this.postDetails.uploadtype != '' ? formData.append('uploadtype', this.postDetails.uploadtype) : null;
      this.postDetails.video != undefined ? formData.append('video', this.postDetails.video) : null;
      let url;
      if (!postData.id) {
        url = this.apiUrl + 'post';
      }
      else {
        formData.append('id', this.postDetails.id)
        url = this.apiUrl + 'post/' + postData.id;
      }
      this.http.post(url, formData,)
        .subscribe((data: any) => {
          // this.appComponent.stopSpinner();
          //this.appComponent.showNotification("success", "Data Saved Successfull");
          // this.postId = data;
          if (data.isSucceeded) {
            this.router.navigate(['/post/' + this.postDetails.menuId]);
          }
          else {
            alert(data.message);
          }
          this.appComponent.stopSpinner()
        });
    }

  }
  deleteFiles(id) {
    //  let img = JSON.parse(image)
    const index = this._files.findIndex(obj => obj.id == id);
    if (index > -1) {
      this._files.splice(index, 1);
    }
  }
  //deleteFile(id, file, image) {
  //  let img = JSON.parse(image)
  //  const index = img.indexOf(file);
  //  if (index > -1) {
  //    img.splice(index, 1);
  //  }
  //  console.log(img.toString())
  //  let formData = new FormData();
  //  formData.append('image', img.toString());
  //  let url = this.apiUrl + 'post/updateimage/' + id;
  //  this.http.post(url, formData,)
  //    .subscribe((data: any) => {
  //      if (data) {
  //        this.getPostDetails(id);
  //      }
  //      else {
  //        alert(data.message);
  //      }
  //      this.appComponent.stopSpinner()
  //    });
  //}
  enableButton() {
    var n = $(".imgcheckbox:checked").length;
    if (n > 0) {
      this.enableOk = false;

    }
  }
  openModel() {
    let $this = this;
    let closeModel = true;
    //this._files = [];
    let ids = []
    $('.imgcheckbox:checked').each(function (index) {
      ids.push(parseInt(($(this).val())));
    });
    this.showMediaModel = false;
    ids.forEach(function (id, i) {
      $this.media.forEach(function (value) {
        if (id == value.id) {
          $this._images.push({ id: id, file: `${$this.imageurl}${value.filepath}`, fileName: "url" });
          if (value.filepath.split(".")[1] == 'webp') {
            let url = $this.apiUrl + "media/getFile?filepath="
            $this._imagesPrev.push({ id: id, file: `${$this.imageurl}${value.filepath}`, imageUrl: `${url}${value.filepath}` });
            $this.selectedFileId = $this._imagesPrev[0].id;
            $this.imageFile = $this._imagesPrev[0].imageUrl;
            $("#mediaModel").css("display", "none");
            $("#myModal").css("display", "block");
            $(".modal-backdrop .in").css("opacity", "0");
            $(".modal - backdrop").css("z-index", "0");
            $this.showMediaModel = true;
            closeModel = false;
          }
        }
      })
    })
    if (closeModel == true) {
        this._images.forEach(function (item) {
          if (item.fileName != "blob")
            $this.mediaIds.push(item.id);
        })
        $this.getMedia('pushMedia');
        this.closeModal();
      
    }
    
  }
  renameModel(id, value) {
    this.mediaId = id;
    this.renameValue = value;
    $("#reNameModel").css("display", "block");
    $(".modal-backdrop .in").css("opacity", "0");
    $(".modal - backdrop").css("z-index", "0");
  }
  rename() {
    this._files.find(x => x.id == this.mediaId).caption = this.renameValue;
    this.closeModal();
  }
  openMedia() {

    $("#mediaModel").css("display", "block");
    $(".modal-backdrop .in").css("opacity", "0");
    $(".modal - backdrop").css("z-index", "0");
  }
  closeModal() {
    $("#mediaModel").css("display", "none");
    $("#myModal").css("display", "none");
    $("#reNameModel").css("display", "none");
  }

  get_hostname(url) {
    var m = url.match(/^http:\/\/[^/]+/) || url.match(/^https:\/\/[^/]+/);
    return m ? m[0] : null;
  }


  editImage(file, id) {
    this.imageFile = file;
    this.selectedFileId = id;
  }


  fileChangeEvent(event: any): void {
    let formData = new FormData();
    if (event.target.files.length > 0) {
      for (var i = 0; i < event.target.files.length; i++) {
        formData.append('file', event.target.files[i]);
      }
    }
    let url = this.apiUrl + 'media/upload';
    this.http.post(url, formData)
      .subscribe((data: any) => {
        this.getMedia(null);

      });
  }
  saveCroppedImage() {
    let formData = new FormData();
    let $this = this;
    this._images.forEach(function (item) {
      formData.append('files[]', item.file);
      if(item.fileName!="blob")
      $this.mediaIds.push(item.id);
    })
    let url = this.apiUrl + 'media/upload';
    this.http.post(url, formData)
      .subscribe((data: any) => {
        data.forEach(function (id) {
          $this.mediaIds.push(id);
        })
        console.log($this.mediaIds);
        this.closeModal();
        this.getMedia('pushMedia');
      });
  }

  _images: any = [];

  imageCropped(event: ImageCroppedEvent, id) {
    var $this = this;
    if (this._images.length > 0) {

      if (this._images.find(a => a.id == id) != undefined) {
        this._images.find(a => a.id == id).file = base64ToFile(event.base64);
        this._images.find(a => a.id == id).fileName = 'blob';
      }
    }
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }
  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }



  getBase64Image(img: any) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/webp");
    return dataURL.replace(/^data:image\/(png|jpg|webp);base64,/, "");
  }

  changeAspectRatio() {
    this.aspectRatio = this.aspectRatio;
  }
}

