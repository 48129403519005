<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Main content -->
  <section class="content pt-3">

    <div class="row"  style=" height: 535px;">
      <div class="col-md-12">
        <div class="card card-outline card-info">
          <!-- /.card-header -->
          <div class="card-body pad">
            <form role="form">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h4>Add Category</h4>
                    </div>
                    <div class="col-sm-6">
                      <button class="btn btn-primary btn-sm mr-1 float-right" (click)="SaveCategoryDetails(newCategory)"
                      *ngIf="userPermissions.includes('createcategory') ||userPermissions.includes('editcategory') || userRoles.includes('SibinAdmin')">Save</button>
                      <button class="btn btn-primary btn-sm mr-1 float-right" (click)="ClearCategoryDetails()">Clear</button>
                    </div>
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <!-- text input -->

                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Menu</label>
                    <span><strong style="font-size: 20px;color : red;">*</strong></span>
                    <select name="menu" [(ngModel)]="newCategory.menuId"
                            [ngModelOptions]="{standalone: true}" id="menu" class="form-control">
                      <option [ngValue]="selected">--Select--</option>
                      <option *ngFor="let menu of menuList" value="{{menu.id}}">{{menu.title}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Category</label>
                    <span><strong style="font-size: 20px;color : red;">*</strong></span>
                    <input type="text" class="form-control" required id="categoryName" name="categoryName" [(ngModel)]="newCategory.categoryName" [ngModelOptions]="{standalone: true}"
                           #categoryName="ngModel">
                  </div>
                </div>
              </div>


            </form>
          </div>
        </div>
      </div>
      <!-- /.col-->
    </div>
    <!-- ./row -->
  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->

