import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { AuthenticationService } from '../services/authentication.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  push: any=true;
  constructor(private router: Router, private authService: AuthenticationService) { }

  ngOnInit() {
 
  }
  logout() {
    this.authService.logout();
  }

}
