/// <reference path="services/api.service.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MasterconfigurationComponent } from './masterconfiguration/masterconfiguration.component';
import { MenuComponent } from './masterconfiguration/menu/menu.component';
import { CategoryComponent } from './masterconfiguration/category/category.component';
import { AlbumComponent } from './masterconfiguration/album/album.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PostComponent } from './post/post.component';
import { CreateComponent } from './post/create/create.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DatePipe } from '@angular/common';
import { HttpModule, JsonpModule } from '@angular/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HomeComponent } from './home/home.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CommonService } from './Services/common.service';
import { AuthGuard } from './helpers/auth.guard';  
import { MediaLibraryComponent } from './media-library/media-library.component';
import { FileManagerModule, NavigationPaneService, ToolbarService, DetailsViewService } from '@syncfusion/ej2-angular-filemanager';
import { AuthenticationService } from './services/authentication.service';
import { UsersComponent } from './users/users.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { RolesComponent } from './roles/roles.component';
import { CreateRolesComponent } from './roles/create-roles/create-roles.component';
import { EditRolesComponent } from './roles/edit-roles/edit-roles.component';
import { ModulesComponent } from './modules/modules.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { RolesListComponent } from './components/roles-list/roles-list.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DisableDirective } from './services/disable-directive';
import { AuthRoutingModule } from './Auth/auth-routing.module';
import { LoginComponent } from './Auth/login/login.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { ReplacePipe } from './services/replace.pipe';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    MasterconfigurationComponent,
    MenuComponent,
    CategoryComponent,
    AlbumComponent,
    PostComponent,
    CreateComponent,
    DashboardComponent,
    HomeComponent,
    MediaLibraryComponent,
    UsersComponent,
    CreateUserComponent,
    EditUserComponent,
    RolesComponent,
    CreateRolesComponent,
    EditRolesComponent,
    ModulesComponent,
    UsersListComponent,
    RolesListComponent,
    DisableDirective,
    LoginComponent,
    ReplacePipe
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    HttpModule,
    EditorModule,
    NgxSummernoteModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    DragDropModule,
    ImageCropperModule,
    NgxSpinnerModule,
    AngularEditorModule,
    FileManagerModule, 
    PasswordStrengthMeterModule,
    JsonpModule,
    AuthRoutingModule,
    AutocompleteLibModule,
    NotifierModule.withConfig(customNotifierOptions),
    RouterModule.forRoot([
      {
        path: '', component: HomeComponent, canActivate: [AuthGuard],
        children: [
          { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
          { path: 'master-configuration/:type', component: MasterconfigurationComponent, canActivate: [AuthGuard] },
          { path: 'master-configuration/album/create', component: AlbumComponent, canActivate: [AuthGuard] },
          { path: 'master-configuration/category/create', component: CategoryComponent, canActivate: [AuthGuard] },
          { path: 'master-configuration/menu/create', component: MenuComponent, canActivate: [AuthGuard] },
          { path: 'post/:id', component: PostComponent, canActivate: [AuthGuard] },
          { path: 'post/create/:id', component: CreateComponent, canActivate: [AuthGuard] },
          { path: 'media-library', component: MediaLibraryComponent, canActivate: [AuthGuard] },
          {
            path: 'users', component: UsersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'users/create', component: CreateUserComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'users/edit/:userId', component: EditUserComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'roles', component: RolesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'roles/create', component: CreateRolesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'roles/edit/:roleId', component: EditRolesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'modules', component: ModulesComponent,
            canActivate: [AuthGuard],
          },
        ],
      }
    ],
      { useHash: true })
  ],
  providers: [DatePipe, AuthenticationService, AuthGuard, CommonService, NavigationPaneService, ToolbarService, DetailsViewService],
  bootstrap: [AppComponent]
})
export class AppModule { }
