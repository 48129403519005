

<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <h1 class="w-100">
            All Media
          </h1>
       
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="row" style="height: 475px;">
        <div class="col-12">
          <div class="card">
            <div class="card-header"  id="cardheader">
              <div class="row">
                <div class="col-9">
                  <div class="row">
                    <div class="upload-lable" style=" width: 130px; text-align:center"
                     *ngIf="userPermissions.includes('uploadmedia') || userRoles.includes('SibinAdmin')">
                      <input type="file" class="form-control" (change)="fileChangeEvent($event)" multiple hidden
                             id="files" name="files" (ngModel)="newMedia.files" />
                      <label for="files" style=" padding-top: 7px;"><i class="fas fa-upload fa-plus-sm mr-1"></i>Upload files</label>
                    </div>
                    <div class="rename-lable" (click)="download()" style=" width: 115px;text-align:center"
                     *ngIf="userPermissions.includes('downloadmedia')  || userRoles.includes('SibinAdmin')">
                      <label style=" padding-top: 7px;"><i class="fas fa-download">  Download</i></label>
                    </div>
                    <div class="files-lable" (click)="delete()" style=" width: 115px;text-align:center"
                         *ngIf="userPermissions.includes('deletemedia') || userRoles.includes('SibinAdmin')">
                      <label style=" padding-top: 7px;"><i class="fas fa-trash">  Delete</i></label>
                    </div>
                    <div class="rename-lable" (click)="openModel()" style=" width: 115px; text-align: center"
                         *ngIf="userPermissions.includes('editmedia')  || userRoles.includes('SibinAdmin')">
                      <label style=" padding-top: 7px;"><i class="fas fa-pen">  Rename</i></label>
                    </div>
                  </div>
                  </div>
               
                <div class="col-3">
                  <div class="row">
                    <div class="files-lable" style=" width: 140px; text-align: center">
                    </div>
                    <div class="files-lable" (click)="clearSelection()" style=" width: 140px; text-align: center">
                      <label style=" padding-top: 7px;"><i class="fas fa-times">  Clear selection</i></label>
                    </div>
                    <div class="upload-lable" style=" width: 140px; text-align: center">
                      <label style="padding-top: 7px;"><i class="fas fa-filter">  Filter</i></label>
                    </div>
                    <div class="col-6 p-0 card" id="filter-card">
                      <p class="mb-0 p-2 cursor-pointer"><input type="checkbox" class="filter-data" value="pdf" (click)="filter('pdf')"> pdf</p>
                      <p class="mb-0 p-2 cursor-pointer"><input type="checkbox" class="filter-data" value="excel" (click)="filter('excel')"> excel</p>
                      <p class="mb-0 p-2 cursor-pointer"><input type="checkbox" class="filter-data" value="image" (click)="filter('image')"> png,jpg,jpeg</p>
                      <p class="mb-0 p-2 cursor-pointer"  (click)="filter('clear')">Clear Filter</p>
                    </div>
                    <div class="upload-lable" style=" width: 120px; text-align: center">
                      <label style="padding-top: 7px;"><i class="fas fa-sort">  Sort</i></label>
                    </div>
                    <div class="col-6 p-0 card" id="sort-card">
                      <p class="mb-0 p-2 cursor-pointer" (click)="sort('filename')"> A-Z</p>
                      <p class="mb-0 p-2 cursor-pointer" (click)="sort('createdon')"> Datetime</p>
                    </div>
                  </div>
                 
                </div>
                <!--<div class="card-tools">
    <div class="input-group input-group" style="width: 350px;">
      <input type="text" [(ngModel)]="Search" class="form-control float-right" placeholder="Search" (ngModelChange)="paginationPageNumber = 1">

      <div class="input-group-append">
        <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
      </div>
    </div>
  </div>-->
              </div>
            </div>
            <div class="card-body">
              <div class="row" *ngIf="( media |  filter:Search) as result">
                <p *ngIf="result.length === 0">Sorry no records found..</p>
                <div class="col-md-2 imgdiv mb-3" *ngFor="let data of result">

                  <img src="{{data.displayUrl}}" class="img-thumbnail"  alt="{{data.filename}}" />
                  <input type="checkbox" class="imgcheckbox" id="{{data.id}}" value="{{data.id}}" (click)="hideShowButton()" />

                  <p class="mb-0" title="File Name: {{data.filename}}  Created Date: {{data.createdon }} URL: {{data.displayUrl}}">
                    {{data.filename.length>19?data.filename.substring(0, 19) + "..":data.filename}}<br />
                    {{data.createdon|date:'dd-MM-yyyy'}}<br />
                    <!--Url:<span id="copy_{{data.id}}">{{data.displayUrl.length>14?data.displayUrl.substring(0, 14) + "..":data.displayUrl}}</span>-->
                    Copy File Link
                    <i style="color:blue;cursor:pointer;" class="fa fa-clone" title="Copy" (click)="copy(data.filepath)"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div id="myModal" class="modal" style=" overflow: auto;">
  <div class="modal-sm" style=" margin: 1.75rem auto;">
    <div class="modal-content">
      <div class="modal-header flex-column"> <h4 class="modal-title"> Rename</h4>
      <button type="button" class="close float-right" (click)="closeModal()" aria-hidden="true"
              style="margin-top: -64px; font-size: 39px;">&times; </button> </div>
      <div class="modal-body">
     <input type="text" class="form-control" [(ngModel)]="renameValue" />
    

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary float-right" (click)="rename()" data-dismiss="modal">Ok</button>
      </div>
     
    </div>
  </div>
  </div>
