import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  apiUrl: string;
    menuList: any;
  newCategory: any = {};
  selected: any;
    id: any;
    userRoles: any;
    userPermissions: any;
  constructor(private http: HttpClient, private appComponent: AppComponent,
    private router: Router) {

  }

  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    this.id = localStorage.getItem('id');
    this.apiUrl = this.appComponent.APIURL;
    if (this.id != null) {
      this.getSelectedCategory(this.id);
    }
    this.getAllMenuMasterDetails();
  }
  ClearCategoryDetails() {
    this.newCategory = {};
  }
  getSelectedCategory(id) {
    this.http.get(this.apiUrl + 'CategoryMaster/' + id, {
    }).subscribe((data: any) => {
      this.newCategory = data.categoryMasterDTO[0];
      this.appComponent.stopSpinner();
    }
      , (err) => {
        this.appComponent.stopSpinner();
      });
  }
  getAllMenuMasterDetails() {
    let id = 0;
    this.http.get(this.apiUrl + 'MenuMaster/'+id, {
    }).subscribe((data: any) => {
      this.menuList = data.menuMasterDTO;
     // this.appComponent.stopSpinner();
    }, (err) => {
     // this.appComponent.stopSpinner();
    });
  }

  SaveCategoryDetails(newcategoryData) {
    //this.appComponent.startSpinner();
    let url;
    if (!newcategoryData.id) {
      url = this.apiUrl + 'CategoryMaster';
    }
    else {
      url = this.apiUrl + 'CategoryMaster/' + newcategoryData.id;
    }
    this.http.post(url, newcategoryData, {
    }).subscribe((data:any) => {
      if (!data.isSucceeded) {
        alert(data.message);
      }
      else {
        this.router.navigate(['/master-configuration/category']);
      }
      
    }
      , (err) => {
       // this.appComponent.stopSpinner();
      });
  }
}
