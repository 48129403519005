import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Local } from 'protractor/built/driverProviders';
import { AppComponent } from '../../app.component';
import { SidebarComponent } from '../../sidebar/sidebar.component';

declare var $: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  apiUrl: any;
  newMenu: any = {};
  MenuType: string;
  menuList: any;
  selected: any;
  id: string;
  allRoute: any;
  newRoutingPath: any = {};
    userRoles: any;
    userPermissions: any;
  /* Menu master ctor */
  constructor(private http: HttpClient, private appComponent: AppComponent,
    private router: Router) {


  }
  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    this.MenuType = "";
    this.apiUrl = this.appComponent.APIURL;
    this.id = localStorage.getItem('id');
    if (this.id != null) {
      this.getSelectedMenu(this.id);      
    }
    this.getAllMenuMaster();
    this.getAllRoutingPath();
    this.newMenu.routinglink = 'internal';
  }
  getAllRoutingPath() {
    let id = 0;
    this.http.get(this.apiUrl + 'MenuMaster/getRoutingPath/' + id, {
    }).subscribe((data: any) => {
      this.allRoute = data.menuMasterDTO;
    });
  }
  getSelectedMenu(id) {
    this.http.get(this.apiUrl + 'MenuMaster/' + id, {
    }).subscribe((data: any) => {
      this.newMenu = data.menuMasterDTO[0];
      if (this.newMenu.parentId == 0) {
        this.MenuType = 'mainMenu';
      }
      else {
        this.MenuType = 'subMenu';
      }
    });

  }

  getAllMenuMaster() {
    let id = 0;
    this.http.get(this.apiUrl + 'MenuMaster/' + id, {
    }).subscribe((data: any) => {
      this.menuList = data.menuMasterDTO;
    });
  }
  ClearMenuDetails() {
    this.newMenu = {};
    this.MenuType = '';
  }

  SaveMenuDetails(newMenuData) {
    // this.appComponent.startSpinner();
    let url;
    console.log(newMenuData.id);
    if (!newMenuData.id) {
      newMenuData.createdBy = "admin";//localStorage.getItem('userid');
      url = this.apiUrl + 'MenuMaster';
    }
    else {
      newMenuData.updatedBy = "admin";//localStorage.getItem('userid');
      url = this.apiUrl + 'MenuMaster/' + newMenuData.id;
    }
    newMenuData.status = "Active";
    this.http.post(url, newMenuData, {
    }).subscribe((data: any) => {
      if (data.isSucceeded) {
        this.router.navigate(['/master-configuration/menu']);
      }
      else {
      
        alert(data.message);
      }
    
    }
      , (err) => {
        // this.appComponent.stopSpinner();
      });
  }
  saveRoutingPath(newRoutingPath) {
    newRoutingPath.link = newRoutingPath.routingpath;
    let url = this.apiUrl + 'MenuMaster/saveRoutingPath';
    this.http.post(url, newRoutingPath, {
    }).subscribe((data: any) => {
      if (data.isSucceeded) {
        this.getAllRoutingPath();
        $('#addModal').fadeOut();
        $('.modal-backdrop').removeClass('modal-backdrop');;
      }
      else {
        alert(data.message);
      }
    }
      , (err) => {
        // this.appComponent.stopSpinner();
      });
      }
  deleteRoutingPath(id) {
    alert(id);
  }
}
