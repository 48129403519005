<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Main content -->
  <section class="content pt-3">

    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-info">
          <!-- /.card-header -->
          <div class="card-body pad">
            <form role="form">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h4>Manage {{menuList?menuList.title:null}}</h4>

                    </div>
                    <div class="col-sm-6">
                      <a href="javaScript:void(0)" class="float-right cursor-pointer"
                         *ngIf="postDetails.type == 'gallery' && (userPermissions.includes('managealbums') || userRoles.includes('SibinAdmin'))" (click)="manageAlbums()">Manage Albums</a>
                    </div>
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <input type="hidden" class="form-control" id="id" name="id" [(ngModel)]="postDetails.id"
                     [ngModelOptions]="{standalone: true}" value="{{postDetails.id}}">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group text-dark">
                    <label for="type">Post Type </label>
                    <select name="type" [(ngModel)]="postDetails.type"
                            [ngModelOptions]="{standalone: true}" #state="ngModel" id="type" class="form-control">
                      <option value="post" *ngIf="menuList && menuList.post">Post</option>
                      <option value="blog" *ngIf="menuList && menuList.blog">Blog</option>
                      <option value="gallery" *ngIf="menuList && menuList.gallery">Gallery</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group text-dark">
                    <label for="menu">Menu</label>
                    <select name="menu" [(ngModel)]="postDetails.menuId"
                            [ngModelOptions]="{standalone: true}" id="menu" class="form-control" disabled>
                      <option *ngIf="menuList" value="{{menuList.id}}">{{menuList.title}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="{{postDetails.type != 'gallery'? 'col-sm-12' : 'col-sm-6'}}">
                  <div class="form-group">
                    <input type="text" id="title" name="title" [(ngModel)]="postDetails.title" [ngModelOptions]="{standalone: true}"
                           required class="form-control form-control-lg h2" placeholder="Title">
                  </div>
                </div>
                <div class="col-sm-6 mt-n1" *ngIf=" postDetails.type == 'gallery'">
                  <div class="form-group row">
                    <div class="col-sm-4">
                      <label class="w-100">Album Type<strong style="color: #ff0000">*</strong></label>
                      <input type="radio" id="photo" class="mr-1" name="albumtype" [(ngModel)]="postDetails.albumtype" [ngModelOptions]="{standalone: true}" value="photo">
                      <label for="photo" class="mr-2">Photo</label>
                      <input type="radio" class="mr-1" id="video" name="albumtype" [(ngModel)]="postDetails.albumtype" [ngModelOptions]="{standalone: true}" value="video">
                      <label for="video">Video</label>
                    </div>
                    <!--<div class="col-sm-8" *ngIf="postDetails.albumtype == 'video'">
                      <label class="w-100">File Upload Type<strong style="color: #ff0000">*</strong></label>
                      <input type="radio" id="browse" name="uploadtype" class="mr-1" [(ngModel)]="postDetails.uploadtype" [ngModelOptions]="{standalone: true}" value="browse">
                      <label for="browse" class="mr-2">Browse Image</label>
                      <input type="radio" id="embeded" class="mr-1" name="uploadtype" [(ngModel)]="postDetails.uploadtype" [ngModelOptions]="{standalone: true}" value="embeded">
                      <label for="embeded">Embeded Url</label>
                    </div>-->
                  </div>
                </div>
                <div class="col-sm-12 mb-3" *ngIf="postDetails.type != 'gallery'">
                  <input [ngxSummernote]="config" [(ngModel)]="postDetails.content" id="content" [ngModelOptions]="{standalone: true}" #content>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" *ngIf="postDetails.type != 'gallery'">
                    <label>Categories</label>
                    <select name="category" [(ngModel)]="postDetails.categoryId" disabled="{{postDetails.categoryId?'true':'false'}}"
                            [ngModelOptions]="{standalone: true}" id="categoryName" class="form-control">
                      <option value="">--Select--</option>
                      <option *ngFor="let category of categoryList" value="{{category.id}}">{{category.categoryName}}</option>
                    </select>
                  </div>
                  <div class="form-group" *ngIf="postDetails.type == 'gallery'">
                    <label>Album Name</label>
                    <select name="album" [(ngModel)]="postDetails.albumid"
                            [ngModelOptions]="{standalone: true}" id="albumname" class="form-control">
                      <option value="">--Select--</option>
                      <option *ngFor="let album of albumList" value="{{album.id}}">{{album.albumname}}</option>
                    </select>
                    <a href="javaScript:void(0)" (click)="addAlbum()" class="cursor-pointer"
                       *ngIf="userPermissions.includes('createalbum') || userRoles.includes('SibinAdmin')">Add New Album</a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group text-dark" *ngIf="postDetails.type=='post'||postDetails.albumtype==null || postDetails.albumtype=='photo'">
                    <label *ngIf="postDetails.type == 'gallery'" for="file">
                      File<i class="text-muted" style="font-size:13px"> (File type: {{imageext.join(', ') +', '+ fileext.join(', ')}} )</i>
                    </label>

                    <label *ngIf="postDetails.type != 'gallery'" for="file">
                      Featured Image<i class="text-muted" style="font-size:13px"> (File type: {{imageext.join(', ')}} )</i>
                    </label>

                    <div>
                      <!--<input *ngIf="postDetails.type == 'gallery'" type="file" class="form-control" (change)="fileChangeEvent($event)"
                             id="file" name="file" [(ngModel)]="postDetails.image" multiple />
                      <input *ngIf="postDetails.type != 'gallery'" type="file" class="form-control" (change)="fileChangeEvent($event)"
                             id="file" name="file" [(ngModel)]="postDetails.image" />-->
                      <!-- <button class="btn btn-danger" (click)="openMedia()">browse</button>-->
                      <label class="form-control btn btn-dark lableFile" (click)="openMedia()">Browse File</label>
                    </div>
                  </div>
                  <div class="form-group text-dark" *ngIf="postDetails.albumtype == 'video'">
                    <label *ngIf="postDetails.type != 'gallery'">File</label>
                    <label *ngIf="postDetails.type == 'gallery'">Embededd Url Video</label>
                    <div>
                      <textarea type="textarea" class="form-control" id="embeddedvideo" name="video" [(ngModel)]="postDetails.video"></textarea>
                    </div>
                  </div>
                  <div class="row images pt-1 mt-n3" *ngIf="_files!=undefined">
                    <div class="col-2 file" *ngFor="let file of _files">

                      <img class="img-thumbnail mr-2 mb-2" [src]="file.photo" height="100" width="100" />
                      <p title="{{file.caption}}">
                        <span style="color:blue">{{file.caption.substring(0, 5) + ".."}}</span>
                        <i class="fa fa-edit" style="cursor: pointer;color:blue; margin-left: 3px;"
                           (click)="renameModel(file.id,file.caption)" title="Rename Caption"></i>
                        <i class="fa fa-trash" style="margin-left:5px; color:red;cursor: pointer; "
                           (click)="deleteFiles(file.id)" title="Delete Image"></i>
                      </p>
                    </div>
                  </div>

                  <div class="row " style="margin-top:-15px" *ngIf="postDetails.image && _files==undefined">

                    <div class=" {{file.split('.')[1]!='webp'?'col-12':'col-2'}}" *ngFor="let file of postDetails.images">
                      <a href="{{downloadimgUrl+file}}" *ngIf="file.split('.')[1]=='webp'" target="blank">
                        <img class="img-thumbnail mr-2 mb-2" *ngIf="file.split('.')[1]=='webp'"
                             [src]="imageurlMedium+file" height="100" width="100" />
                      </a>
                      <a href="{{downloadimgUrl+file}}" *ngIf="file.split('.')[1]!='webp'" target="blank">{{file.split('.')[0]+'.'+file.split('.').reverse()[0]}} </a>
                      <i class="fa fa-times" *ngIf="file.split('.')[1]!='webp'" style="color: red; cursor: pointer; position: absolute;margin-left:3px "
                         (click)="deleteFile(postDetails.id,file, postDetails.image)" title="Delete"></i>
                      <i class="fa fa-times" *ngIf="file.split('.')[1]=='webp'" style="color: red; margin: -80px 0px 0px 57px; cursor: pointer; position: absolute; "
                         (click)="deleteFile(postDetails.id,file, postDetails.image)" title="Delete"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="postDetails.type != 'gallery'">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Short Description</label>
<textarea class="form-control" placeholder="Place some text here" id="shortDescription" name="shortDescription" [(ngModel)]="postDetails.shortDescription" [ngModelOptions]="{standalone: true}" style="width: 100%; height: auto; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;"></textarea>
                  </div>
                </div>
                <div class="col-sm-12">
                  <!-- text input -->
                  <div class="form-group">
                    <label>Embedded video url</label>
<textarea class="form-control" placeholder="Please copy embedded code and past it here." id="embdedUrl" name="embdedUrl" [(ngModel)]="postDetails.embdedUrl" [ngModelOptions]="{standalone: true}" style="width: 100%; height: auto; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;"></textarea>
                  </div>
                </div> <!-- <div class="form-group"> <label>Embedded Url</label> <input type="text" class="form-control" id="embdedUrl" name="embdedUrl" [(ngModel)]="postDetails.embdedUrl" [ngModelOptions]="{standalone: true}"> </div> -->
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <!-- text input -->
                  <div class="form-group">
                    <input type="checkbox" [(ngModel)]="postDetails.allowlike" id="allowlike" name="allowlike">
                    <label class="ml-2">Likes</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <!-- text input -->
                  <div class="form-group">
                    <input type="checkbox" [(ngModel)]="postDetails.allowcomment" id="allowcomment" name="allowcomment">
                    <label class="ml-2"> Comment</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <!-- text input -->
                  <div class="form-group">
                    <input type="checkbox" [(ngModel)]="postDetails.allowfollow" id="allowfollow" name="allowfollow">
                    <label class="ml-2"> Follow</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <!-- text input -->
                  <div class="form-group">
                    <input type="checkbox" [(ngModel)]="postDetails.displayonhome" id="displayonhome" name="displayonhome" [ngModelOptions]="{standalone: true}">
                    <label class="ml-2">Display on Home</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <!-- text input -->
                  <div class="form-group">
                    <label>Author</label>
                    <input type="text" class="form-control" id="author" name="author" [(ngModel)]="postDetails.author" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
                <div class="col-sm-6">
                  <!-- text input -->
                  <div class="form-group">
                    <label>Post Date</label>
                    <input type="date" class="form-control" id="postdate" name="postdate" required [(ngModel)]="postDetails.postdate" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <button class="btn btn-primary btn-sm mr-1 float-right"
                      *ngIf="menuList?userPermissions.includes('editpost') ||
                      userPermissions.includes('createpost')  || userRoles.includes('SibinAdmin'):null"
                      (click)="savePost(postDetails)">
                Save
              </button>
              <button class="btn btn-primary btn-sm mr-1 float-right" (click)="ClearPost()">Clear</button>
            </form>
          </div>
        </div>
      </div> <!-- /.col-->
    </div> <!-- ./row -->
  </section> <!-- /.content -->
</div> <!-- /.content-wrapper --> <!-- Control Sidebar --> <!-- <aside class="control-sidebar control-sidebar-light" style="width:400px"> <div class="content-header pb-0"> <h5> <b> Post Option <a class="nav-link float-right" data-slide="true" data-widget="control-sidebar" role="button" style="margin-top:-6px;cursor:pointer"> <i class="fas fa-times fa-times-sm"></i> </a> </b> </h5> <hr /> </div> <div class="card-body pad pb-0"> <form role="form"> </form> </div> --> <!-- Control sidebar content goes here --> <!-- </aside> --> <!-- /.control-sidebar -->
<div id="myModal" class="modal" style=" overflow: auto;">
  <div class="modal-lg" style=" margin: 1.75rem auto;">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title"> Edit Image </h4>
        <button type="button" class="close float-right" (click)="closeModal()" aria-hidden="true" style="margin-top: -64px; font-size: 39px;">&times; </button>
      </div>
      <div class="modal-body">
        <!-- <div class="row"> <div class="col-md-2"> <select name="type" [(ngModel)]="aspectRatio" (change)="changeAspectRatio()" [ngModelOptions]="{standalone: true}" #state="ngModel" id="type"> <option value="1">1/1</option> <option value="1.333333">4/3</option> <option value="1.777778">16/9</option> <option value="2.333333">21/9</option> <option value="3.555555">32/9</option> </select> </div> <div class="col-md-10"> <a (click)="rotateLeft()">left</a> <a (click)="rotateRight()">Rotate right</a> <a (click)="flipHorizontal()">Flip horizontal</a> <a (click)="flipVertical()">Flip vertical</a> <a (click)="zoomOut()">Zoom -</a> <a (click)="zoomIn()">Zoom +</a> <a (click)="resetImage()" title="reset"><i class="fas fa-undo"></i></a> </div> </div> <hr /> -->
        <div class="row mt-2">
          <div class="col-md-12">
            <image-cropper [imageURL]="imageFile" [aspectRatio]="aspectRatio" [canvasRotation]="canvasRotation"
                           (imageCropped)="imageCropped($event, selectedFileId)"
                           (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event )" (loadImageFailed)="loadImageFailed()"> </image-cropper>
          </div> <!-- <div class="col-md-6" style="height: 200px;text-align: center"> <img crossorigin="anonymous" [src]="croppedImage" style="height: 200px; width:300px" /> </div> -->
        </div>
      </div>
      <div class="modal-footer" style="margin-left:auto;margin-right:auto;">
        <div *ngFor="let file of _imagesPrev">
          <img class="img-thumbnail mr-2" id="editThumbs_{{file.id}}" [src]="file.file" height="100" width="100" (click)="editImage(file.imageUrl, file.id)" />

        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary float-right" (click)="saveCroppedImage()" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<div id="mediaModel" class="modal">
  <div class="modal-lg" style=" margin: 0.75rem; min-width: 826px; max-width: 1466px;">
    <div class="modal-content">
      <!--1465px-->
      <div>
        <div class="card-header" style="background-color: #f7f7f7" id="cardheader">
          <div class="row">
            <div class="col-9">
              <div class="row">
                <div class="upload-lable" style=" width: 130px; text-align:center">
                  <input type="file" class="form-control" (change)="fileChangeEvent($event)" multiple hidden
                         id="files" name="files" (ngModel)="newMedia.files" />
                  <label for="files" style=" padding-top: 7px;"><i class="fas fa-upload fa-plus-sm mr-1"></i>Upload files</label>
                </div>
              </div>
            </div>

          </div>
        </div>
        <button type="button" class="close float-right" (click)="closeModal()" aria-hidden="true"
                style="margin-top: -64px; font-size: 39px;">
          &times;
        </button>
      </div>
      <div class="modal-body" style="height: 34rem; overflow: auto;">
        <div class="col-12">
          <div class="row" *ngIf="( media |  filter:Search) as result">
            <p *ngIf="result.length === 0">Sorry no records found..</p>
            <div class="col-md-2 imgdiv mb-3" *ngFor="let data of result">

              <img src="{{data.displayUrl}}" class="img-thumbnail w-100" alt="{{data.filename}}" />
              <input type="checkbox" class="imgcheckbox" id="{{data.id}}" value="{{data.id}}" (click)="enableButton()" />

              <p class="mb-0">
                {{data.filename.length>25?data.filename.substring(0,25)+'..':data.filename}}
              </p>
            </div>
          </div>

        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary float-right" (click)="openModel()" data-dismiss="modal" [disabled]="enableOk">Ok</button>
      </div>

    </div>
  </div>
</div>
<div id="reNameModel" class="modal" style=" overflow: auto;">
  <div class="modal-sm" style=" margin: 1.75rem auto;">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title"> Rename Caption</h4>
        <button type="button" class="close float-right" (click)="closeModal()" aria-hidden="true"
                style="margin-top: -64px; font-size: 39px;">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <input type="text" class="form-control" [(ngModel)]="renameValue" />


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary float-right" (click)="rename()" data-dismiss="modal">Ok</button>
      </div>

    </div>
  </div>
</div>
