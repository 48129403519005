import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppComponent } from '../app.component'; 
import { CommonService } from '../Services/common.service';
@Component({
  selector: 'app-masterconfiguration',
  templateUrl: './masterconfiguration.component.html',
  styleUrls: ['./masterconfiguration.component.css']
})
export class MasterconfigurationComponent implements OnInit {
  masterType: any;
    apiUrl: string;
    menuList: any;
    applicationCount: any;
    menuId: any=[];
    listingOrder: any=[];
    menuCount: any;
    categoryList: any;
    categoryCount: any;
    itemsPerPage: number;
    albumList: any;
    albumCount: any;
    imageurl: string;
    imageurlMedium: string;
    tempList: any;
    archiveCount: any;
  hideShow: boolean = false;
  status:any;
  id: any;
    Search: any;
  paginationPageNumber: number;
  displayonhome: any;
  submenu: any;
    userRoles: any;
    userPermissions: any;
  constructor(private _activeRoute: ActivatedRoute, private http: HttpClient,
    private router: Router, private appComponent: AppComponent, private Service: CommonService) { }

  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    if (localStorage.getItem('reload') == 'true') {
      localStorage.setItem('reload', 'false');
      window.location.reload();
    }
    this.appComponent.startSpinnerWithTime();
    this.apiUrl = this.appComponent.APIURL;
    this.imageurl = this.appComponent.IMAGEURL;
    this.imageurlMedium = this.appComponent.IMAGEURL;
    this._activeRoute.params.subscribe(
      params => {
        this.paginationPageNumber = 1;
        this.masterType = params['type'];
        this.itemsPerPage = 5;
        this.Search = null;
        this.archiveCount = 0;
        this.hideShow = false;
        if (this.masterType == 'menu') {
         
          this.getAllMenuMaster();
        }
        else if (this.masterType == 'category') {
          this.getAllCategoryList();
        }
        else if (this.masterType == 'album') {
          this.getAllAlbums();
        }
      }
    );
   
  }
  backToCreate() {
    let menuId = localStorage.getItem('menuId');
    this.router.navigate(['/post/create/' + menuId]);
  }
  create(link) {
    localStorage.removeItem('id');
    this.router.navigate([link]);
  }
  getAllMenuMaster() {
    let id = 0;
    this.http.get(this.apiUrl + 'MenuMaster/'+id, {
    }).subscribe((data: any) => {
      this.tempList = data.menuMasterDTO;
      this.menuList = this.tempList.filter(x => x.status != "Archive");
      this.archiveCount = this.tempList.filter(x => x.status == "Archive").length;
      this.menuCount = this.menuList.length;
      if (this.hideShow == true ) {
        this.ArchivedRecords();
      }
      this.Service.sendLatestMenu(this.tempList);
     this.appComponent.stopSpinner();
    });
  }
  ArchivedRecords() {
    this.paginationPageNumber=  1;
    this.hideShow = true;
    if (this.masterType == 'menu') {
      this.menuList = this.tempList.filter(x => x.status == "Archive");
      this.menuCount = this.tempList.length;
    }
    else if (this.masterType == 'category') {
      this.categoryList = this.tempList.filter(x => x.status == "Archive");
      this.categoryCount = this.tempList.length;
    }
    else {
      this.albumList = this.tempList.filter(x => x.status == "Archive");
      this.albumCount = this.tempList.length;
    }
  }
  Back() {
    this.hideShow = false;
    this.paginationPageNumber = 1;
    if (this.masterType == 'menu') {
      this.getAllMenuMaster();
    }
    else if (this.masterType == 'category') {
      this.getAllCategoryList();
    }
    else {
      this.getAllAlbums();
    }

  
   
  }
  getAllCategoryList() {
    let id = 0;
    this.http.get(this.apiUrl + 'CategoryMaster/' + id, {
    }).subscribe((data: any) => {
      this.tempList = data.categoryMasterDTO;
      this.categoryList = this.tempList.filter(x => x.status != "Archive");
      this.archiveCount = this.tempList.filter(x => x.status == "Archive").length;
      this.categoryCount = this.categoryList.length;
      if (this.hideShow == true) {
        this.ArchivedRecords();
      }
      this.appComponent.stopSpinner();
    }
      , (err) => {
        console.log("error",err.message)
        this.appComponent.stopSpinner();
      });
  }

  getAllAlbums() {
    this.http.get(this.apiUrl + 'albummaster' ,{
    }).subscribe((data: any) => {
      this.tempList = data.albumDTO;
      this.albumList = this.tempList.filter(x => x.status != "Archive");
      this.archiveCount = this.tempList.filter(x => x.status == "Archive").length;
      this.albumCount = this.albumList.length;
      if (this.hideShow == true ) {
        this.ArchivedRecords();
      }
      this.appComponent.stopSpinner();
    });
  }

  edit(data) {
    localStorage.setItem('id', data.id);
    if (this.masterType == 'menu') {
      this.router.navigate(['/master-configuration/menu/create']);
    }
    else if (this.masterType == 'category') {
      this.router.navigate(['/master-configuration/category/create']);
    }
    else if (this.masterType == 'album') {
      this.router.navigate(['/master-configuration/album/create']);
    }
  }
  delete(id) {
    // this.appComponent.startSpinner();
    let url;
    if (this.masterType == 'menu') {
      url = this.apiUrl + 'MenuMaster/' + id
    }
    else if (this.masterType == 'category') {
      url = this.apiUrl + 'CategoryMaster/' + id
    }
    this.http.delete(url, {
    }).subscribe(data => {
      if (data) {
        alert("deleted..")
        if (this.masterType == 'menu') {
          this.getAllMenuMaster();
        }
        else if (this.masterType == 'category') {
          this.getAllCategoryList();
        }
      }
      else {
        alert("Sorry could not delete..")
      }
      this.appComponent.stopSpinner();
    }
      , (err) => {
         this.appComponent.stopSpinner();
      });
  }
 
  dropList(event: CdkDragDrop<string[]>) {
    this.appComponent.startSpinner();
    this.menuId = [];
    this.listingOrder = [];
    moveItemInArray(this.menuList, event.previousIndex, event.currentIndex);
    if (this.menuList[0].listingOrder == 1) {
      for (var i = 0; i < this.menuList.length; i++) {
        this.menuId.push(this.menuList[i].id);
        this.listingOrder.push(this.menuList[0].listingOrder + i);
      }
      this.updateListingOrder(this.menuId, this.listingOrder);
    }
    if (this.menuList[0].listingOrder != 1) {
      for (var i = 0; i < this.menuList.length; i++) {
        this.menuId.push(this.menuList[i].id);
        this.listingOrder.push(i + 1);
      }
      this.updateListingOrder(this.menuId, this.listingOrder);
    }
 
  }
  updateListingOrder(id, listingOrder) {
   // this.appComponent.startSpinner();
    this.http.put(this.apiUrl + 'MenuMaster/' + JSON.stringify(id) + '/' + JSON.stringify(listingOrder), {
    }).subscribe(data => {
      this.getAllMenuMaster();
      this.appComponent.stopSpinner();
    }
      , (err) => {
       this.appComponent.stopSpinner();
      });
  }
  changeMenuStatus(id, status, columnName) {
    this.appComponent.startSpinner();
    if (status == 'Active') {
      status = 'InActive';
    }
    else if (status == 'InActive') {
      status = 'Active';
    }
    else if (status == 1) {
      status = true;
    }
    else if (status == 0) {
      status = false;
    }
    let url;
    if (this.masterType == 'menu') {
      url = this.apiUrl + 'menumaster/' + id + '/' + status + '/' + columnName;
    }
    else if (this.masterType == 'category') {
      url = this.apiUrl + 'CategoryMaster/' + id + '/' + status 
    }
    else if (this.masterType == 'album') {
      url = this.apiUrl + 'albummaster/' + id + '/' + status 
    }
    this.http.post(url, {
    }).subscribe(data => {
     if (this.masterType == 'menu' ) {
        this.getAllMenuMaster();
      }
      else if (this.masterType == 'category') {
        this.getAllCategoryList();
      }
      else {
        this.getAllAlbums();
      }
    });
    this.appComponent.stopSpinner();
  }
  filterMedia()
  {

  }
  isDesc: any;
  column: any;
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    let filterList;
    if (this.masterType == 'menu') {
      filterList = this.menuList;
    }
    else if (this.masterType = 'category') {
      filterList = this.categoryList;
    }
    else if (this.masterType = 'album') {
      filterList = this.albumList;
    }
    filterList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };


  itemPerPagePagination(event: any) {
    this.itemsPerPage = event.target.value;
  }
}
