import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {
  apiUrl: string;
  postList: any;
  menuId: any;
  menuList: any = {};
  listingOrder: any = [];
  id: any = [];
  postCount: any;
    hideShow: boolean=false;
    archiveCount: any;
    tempList: any;
  Search: any;
  paginationPageNumber: any;
  status: any;
  displayonhome: any;
    userRoles: any;
    userPermissions: any;
  constructor(private http: HttpClient,
    private router: Router, private _activeRoute: ActivatedRoute, private appComponent: AppComponent) { }

  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    this.apiUrl = this.appComponent.APIURL;
    this._activeRoute.params.subscribe(
      params => {
        this.postCount = 0;
        this.hideShow = false;
        this.paginationPageNumber = 1;
        this.itemsPerPage = 5;
        this.Search = null;
        this.menuId = params['id'];
        this.getPostDetails(this.menuId);
        this.getMenuMaster(this.menuId);
      }
    );
  }
  create(link) {
    localStorage.removeItem("postId");
    this.router.navigate([link]);
  }
  edit(data) {
    localStorage.setItem("postId", data.id);
    this.router.navigate(['/post/create/' + data.menuId]);
  }
  Back() {
    this.hideShow = false;
    this.paginationPageNumber = 1;
    this.getPostDetails(this.menuId);
    this.getMenuMaster(this.menuId);
  }
  getMenuMaster(menuId) {
    this.http.get(this.apiUrl + 'MenuMaster/' + menuId, {
    }).subscribe((data: any) => {
      this.menuList = data.menuMasterDTO[0];
      // this.appComponent.stopSpinner();
    });
  }
  getPostDetails(menuId) {
    this.http.get(this.apiUrl + 'post/GetPostByMenuId/' + menuId)
      .subscribe((data: any) => {
        this.tempList = data.postDTO;
        this.postList = this.tempList.filter(x => x.status != "Archive");
        this.archiveCount = this.tempList.filter(x => x.status == "Archive").length;
        if (this.hideShow == true) {
          this.ArchivedRecords();
        }
        this.postCount = this.tempList.length;
      })
  }
  ArchivedRecords() {
    this.paginationPageNumber = 1;
    this.hideShow = true;
    this.postList = this.tempList.filter(x => x.status == "Archive");
    this.postCount = this.tempList.length;
  }
  delete(id) {
    // this.appComponent.startSpinner();
    this.http.delete(this.apiUrl + 'post/' + id, {
    }).subscribe(data => {
      if (data) {
        alert("deleted..");
        this.getPostDetails(this.menuId);
      }
      else {
        alert("Sorry could not delete..")
      }

    }
      , (err) => {
        // this.appComponent.stopSpinner();
      });
  }
  changePostStatus(id, status, columnName) {
    if (status == 'Active') {
      status = 'InActive';
    }
    else if (status == 'InActive') {
      status = 'Active';
    }
    this.http.post(this.apiUrl + 'post/' + id + '/' + status + '/' + columnName, {
    }).subscribe(data => {

      if (data == true) {
        this.getPostDetails(this.menuId);
      }
      else {
        alert("somthing went wrong..");
      }
    });
  }

  dropList(event: CdkDragDrop<string[]>) {
    this.id = [];
    this.listingOrder = [];
    moveItemInArray(this.postList, event.previousIndex, event.currentIndex);
    if (this.postList[0].listingOrder == 1) {
      for (var i = 0; i < this.postList.length; i++) {
        this.id.push(this.postList[i].id);
        this.listingOrder.push(this.postList[0].listingorder + i);
      }
      this.updateListingOrder(this.id, this.listingOrder);
    }
    if (this.postList[0].listingOrder != 1) {
      for (var i = 0; i < this.postList.length; i++) {
        this.id.push(this.postList[i].id);
        this.listingOrder.push(i + 1);
      }
      this.updateListingOrder(this.id, this.listingOrder);
    }
  }
  updateListingOrder(id, listingOrder) {
    // this.appComponent.startSpinner();
    this.http.put(this.apiUrl + 'post/' + JSON.stringify(id) + '/' + JSON.stringify(listingOrder), {
    }).subscribe(data => {
      this.getPostDetails(this.menuId);
    }
      , (err) => {
        // this.appComponent.stopSpinner();
      });
  }

  isDesc: any;
  column: any;
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    let filterList;
    filterList = this.postList;
    filterList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  itemsPerPage: any = 5;
  itemPerPagePagination(event: any) {
    this.itemsPerPage = event.target.value;
  }
}
