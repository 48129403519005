<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Main content -->
  <section class="content pt-3">

    <div class="row" style=" height: 535px;">
      <div class="col-md-12">
        <div class="card card-outline card-info">
          <!-- /.card-header -->
          <div class="card-body pad">
            <form role="form" #albumForm ngNativeValidate>
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h4>Add Album</h4>
                    </div>
                    <div class="col-sm-6">
                      <button class="btn btn-primary btn-sm mr-1 float-right" (click)="albumForm.checkValidity()?saveAlbum(newAlbum):null"
                              *ngIf="userPermissions.includes('createalbum') || userPermissions.includes('editalbum') || userRoles.includes('SibinAdmin')">Save</button>
                      <button class="btn btn-primary btn-sm mr-1 float-right" (click)="ClearAlbum()">Clear</button>
                    </div>
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <!-- text input -->

                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <input type="hidden" class="form-control" required id="id" name="id" [(ngModel)]="newAlbum.id" [ngModelOptions]="{standalone: true}"
                           #id="ngModel">
                    <label>Title</label>
                    <span><strong style="font-size: 20px;color : red;">*</strong></span>
                    <input type="text" class="form-control" required id="albumname" name="albumname" [(ngModel)]="newAlbum.albumname" [ngModelOptions]="{standalone: true}"
                           #albumname="ngModel">
                  </div>
                </div>
                <div class="col-md-4" style="margin-top:5px">
                  <div class="form-group">
                    <label>Cover Image</label>

                    <div>
                      <input type="file" class="form-control" (change)="fileChangeEvent($event)"
                             id="coverimage" name="coverimage" (ngModel)="newAlbum.coverimage" />
                      <label for="coverimage" class="form-control btn btn-dark lableFile" style="margin-top: -60px;">{{newAlbum.coverimage?'Change File':'Browse File'}}</label>
                    </div>
                  </div>

                </div>
                <div class="col-md-2" style="margin-top:13px" *ngIf="newAlbum.coverimage && !coverimage">
                  <img class="img-thumbnail mr-2 mb-2" [src]="imageurl+newAlbum.coverimage" height="100" width="100" />
                </div>
                <div class="col-md-2" style="margin-top:13px" *ngIf="coverimage">
                  <img class="img-thumbnail mr-2 mb-2" [src]="coverimage" height="100" width="100" />
                </div>
              </div>


            </form>
          </div>
        </div>
      </div>
      <!-- /.col-->
    </div>
    <!-- ./row -->
  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->
