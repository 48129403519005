  <!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12" *ngIf="postList">
          <h1 class="w-100">
            Manage {{menuList.title}}
            <a class="btn btn-primary float-right text-white"
               (click)="create('/post/create/'+menuList.id)"
               *ngIf="postCount<1 || menuList.multipleEntry && !hideShow &&
               (userPermissions.includes('createpost')   || userRoles.includes('SibinAdmin'))">
              <i class="fas fa-plus fa-plus-sm mr-1"></i>Add {{menuList.title}}
            </a>
            <a *ngIf="archiveCount>0 && !hideShow" class=" float-right mr-2 mt-2 text-danger cursor-pointer" (click)="ArchivedRecords()" style="font-size:15px">
              <i class="nav-icon fas fa-trash "></i>
              Archived Records
            </a>
            <a *ngIf="hideShow" class="float-right mr-2 mt-2 cursor-pointer" (click)="Back()" style="font-size:15px">
              <i class="nav-icon fas fa-arrow-left "></i>
              Back
            </a>
          </h1>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- /.row -->
      <div class="row" style="height:475px;">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-tools float-left">
                <span>
                  <strong style="font-size: 14px;">Please drag and drop if you want to change the listing order.</strong>
                </span>
              </div>
              <div class="card-tools">
                <div class="input-group input-group" style="width: 350px;">
                  <input type="text" [(ngModel)]="Search" class="form-control float-right" placeholder="Search" (ngModelChange)="paginationPageNumber = 1">

                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-head-fixed " cdkDropList
                     (cdkDropListDropped)="dropList($event)">
                <thead>
                  <tr>
                    <th (click)="sort('title')" style="width: 11rem;">
                      Title
                      <span *ngIf="column !='title'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='title' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='title' && !isDesc"><i class="fas fa-sort-down"></i></span>
                    </th>
                    <th (click)="sort('categoryName')" style="width: 11rem;">
                      Category
                      <span *ngIf="column !='categoryName'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='categoryName' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='categoryName' && !isDesc"><i class="fas fa-sort-down"></i></span>
                    </th>
                    <th (click)="sort('displayonhome')" class="text-center">
                      Display on Home
                      <span *ngIf="column !='displayonhome'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='displayonhome' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='displayonhome' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>
                    <th (click)="sort('status')" class="text-center">
                      Status
                      <span *ngIf="column !='status'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='status' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='status' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>
                    <th (click)="sort('listingorder')">
                      Order
                      <span *ngIf="column !='listingorder'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='listingorder' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='listingorder' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>
                    <th (click)="sort('createdOn')">
                      Created On
                      <span *ngIf="column !='createdOn'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='createdOn' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='createdOn' && !isDesc"><i class="fas fa-sort-down"></i></span>
                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="( postList |  filter:Search) as result">
                  <tr *ngIf="result.length === 0"><td colspan="5">Sorry no record found..</td></tr>
                  <tr *ngFor="let item of result | paginate: { itemsPerPage: itemsPerPage,
                                    currentPage: paginationPageNumber }; " cdkDrag
                      [cdkDragDisabled]="!userPermissions.includes('editpost')   && !userRoles.includes('SibinAdmin')"
                      class="{{(userPermissions.includes('editpost')   || userRoles.includes('SibinAdmin'))?'example-box':null}}">
                    <!--<td *ngFor="let list of item | keys">{{item[list]}}</td>-->
                    <td>{{item.title.length>30?item.title.substring(0, 30)+'...':item.title}}</td>
                    <td>{{item.categoryName}}</td>
                    <td class="text-center">
                      <a class="{{item.displayonhome=='0'?'text-success':'text-danger'}} cursor-pointer" (click)="id=item.id;displayonhome=item.displayonhome"
                         data-toggle="modal" data-target="#showhideModal" title="Activate/Deactivate"
                         *ngIf="userPermissions.includes('editpost')  || userRoles.includes('SibinAdmin')">
                        <i class="fa {{item.displayonhome=='0'?'fa-times':'fa-check'}}"></i>
                      </a>
                      <a class="{{item.displayonhome=='0'?'text-success':'text-danger'}} not-allowed" 
                        *ngIf="!userPermissions.includes('editpost')  && !userRoles.includes('SibinAdmin')"
                         title="You do not have permission to edit.">
                        <i class="fa {{item.displayonhome=='0'?'fa-times':'fa-check'}}"></i>
                      </a>
                      <!--<input type="checkbox" [(ngModel)]="item.displayonhome" id="displayonhome" name="displayonhome" [checked]="item.displayonhome" value="item.displayonhome" (click)="changePostStatus(item.id,item.displayonhome,'displayonhome')">-->
                    </td>
                    <td>
                      <a class="{{item.status=='Active'?'text-success':'text-danger'}} cursor-pointer" (click)="id=item.id;status=item.status" data-toggle="modal" data-target="#activeModal" title="Activate/Deactivate">
                        <i class="{{item.status=='Active'?'fas fa-unlock':'fas fa-lock'}}"></i>
                      </a>
                    </td>
                    <td>{{item.listingorder}}</td>
                    <td>{{item.createdOn | date:'dd-MM-yyyy'}}</td>
                    <td>
                      <a *ngIf="!hideShow && (userPermissions.includes('editpost')  || userRoles.includes('SibinAdmin'))"
                         class="text-info cursor-pointer" (click)="edit(item)" title="Update">
                        <i class="fas fa-pencil-alt"> </i>
                        Edit
                      </a>
                      <a *ngIf="!hideShow && (!userPermissions.includes('editpost')  && !userRoles.includes('SibinAdmin'))"
                         class="text-info not-allowed"   title="You do not have permission to edit.">
                        <i class="fas fa-pencil-alt"> </i>
                        Edit
                      </a>
                      <a *ngIf="!hideShow && (userPermissions.includes('deletepost')  || userRoles.includes('SibinAdmin'))"
                         class="text-danger cursor-pointer ml-2" (click)="id=item.id;status='Archive'" data-toggle="modal" data-target="#deleteModal" title="Archive">
                        <i class="fas fa-trash"></i>
                        Archive
                      </a>
                      <a *ngIf="!hideShow && !userPermissions.includes('deletepost')  && !userRoles.includes('SibinAdmin')"
                         class="text-danger not-allowed ml-2"  title="You do not have permission to delete.">
                        <i class="fas fa-trash"></i>
                        Archive
                      </a>
                      <a *ngIf="hideShow" class="text-danger cursor-pointer ml-2" (click)="id=item.id;status='InActive'" data-toggle="modal" data-target="#deleteModal" title="Archive">
                        <i class="fas fa-trash-restore"></i>
                        Unarchive
                      </a>
                    </td>
                  </tr>

                </tbody>
              </table>
              <hr />
              <div class="col-md-12 text-center mt-3">
                <div class="row">
                  <div align="right" class="col-md-4" style="margin-right: -20px;">
                    Page
                    <select style="width: 44px;" (change)="itemPerPagePagination($event)" id="pageItem">
                      <option value="5">5</option>
                      <option *ngIf="postCount>5" value="10">10</option>
                      <option *ngIf="postCount>10" value="25">25</option>
                      <option *ngIf="postCount>25" value="50">50</option>
                      <option *ngIf="postCount>50" value="75">75</option>
                    </select>
                  </div>
                  <div align="left" class="col-md-8">
                    <pagination-controls (pageChange)="paginationPageNumber = $event"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
          </div>

          <!-- /.card -->
        </div>
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->
<div id="deleteModal" class="modal">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <a class="material-icons" id="cross">&times;</a>
        </div>
        <h4 class="modal-title w-100">Are you sure?</h4>
        <!--<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>-->
      </div>
      <div class="modal-body">
        <p>Do you really want to {{status=='Archive'?'Archive':'Unarchive'}} these records?</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="changePostStatus(id,status,'status')" data-dismiss="modal"> {{status=='Archive'?'Archive':'Unarchive'}} </button>
      </div>
    </div>
  </div>
</div>
<div id="activeModal" class="modal">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <a class="material-icons" id="cross">&times;</a>
        </div>
        <h4 class="modal-title w-100">Are you sure?</h4>
        <!--<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>-->
      </div>
      <div class="modal-body">
        <p>Do you really want to {{status=='Active'?'Deactivate':'Activate'}} these records?</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="changePostStatus(id,status,'status')" data-dismiss="modal">{{status=='Active'?'Deactivate':'Activate'}} </button>
      </div>
    </div>
  </div>
</div>
<div id="showhideModal" class="modal">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <a class="material-icons" id="cross">&times;</a>
        </div>
        <h4 class="modal-title w-100">Are you sure?</h4>
        <!--<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>-->
      </div>
      <div class="modal-body">
        <p>Do you really want to {{displayonhome=='0'?'Show':'Hide'}} these records?</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="changePostStatus(id,displayonhome,'displayonhome')" data-dismiss="modal">{{displayonhome=='0'?'Show':'Hide'}} </button>
      </div>
    </div>
  </div>
</div>
