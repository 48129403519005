import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
declare var $: any;
@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.css']
})
export class MediaLibraryComponent implements OnInit {
  newMedia: any = {};
  newAlbum: any = {};
  apiUrl: string;
  id: any;
  imageurl: string;
    media:any=[];
    private _files: any[];
    private _images: any[];
    renameValue: any;
  mediaId: any;
  mediaIds: any = [];
  filterValue: any = [];
    actualData: any;
    userRoles: any;
  userPermissions: any;
  Search: any;
  constructor(private http: HttpClient, private router: Router, private appComponent: AppComponent) { }

  ngOnInit() {
    this.userRoles = [] = this.appComponent.currentUser.user.userRoles;
    this.userPermissions = this.appComponent.permissions;
    this.apiUrl = this.appComponent.APIURL;
    this.imageurl = this.appComponent.IMAGEURL;
    this.getMedia();
  }
  hideShowButton() {
    var n = $("input:checked").length;
    debugger
    if (n == 0) {
      $(".files-lable").css('display', 'none');
      $(".rename-lable").css('display', 'none');
      $(".upload-lable").css('display', 'block');
    }
    if (n > 1) {
      $(".files-lable").css('display', 'block');
      $(".upload-lable").css('display', 'none');
      $(".rename-lable").css('display', 'none');
    }
    if (n ==1) {
      $(".files-lable").css('display', 'block');
      $(".upload-lable").css('display', 'none');
      $(".rename-lable").css('display', 'block');
    }
  }
  getMedia() {
    let $this = this;
    this.http.get(this.apiUrl + 'media', {
    }).subscribe((data: any) => {
      this.actualData = data.mediaDTO;
      this.media = data.mediaDTO;
      this.media.forEach(function (item) {
        if (item.filepath.split(".")[1] == "pdf") {
          item.displayUrl = 'assets/img/icon-pdf.png';
        }
        else if (item.filepath.split(".")[1] == "xls" || item.filepath.split(".")[1] == "xlsx") {
          item.displayUrl = 'assets/img/icon-excel.jpg';
        }
        else {
          item.displayUrl = /*"https://localhost:44393/assets/MediaLibrary/"*/ $this.imageurl + item.filepath;
        }
      })
     
      this.appComponent.stopSpinner();
    });
  }
  delete() {
    let $this = this;
    $('input:checked').each(function () {
      let id = parseInt(($(this).val()));
      $this.mediaIds.push(id);
    });
    this.http.delete(this.apiUrl + 'media/delete/' + JSON.stringify(this.mediaIds), {
    }).subscribe(data => {
      $(".files-lable").css('display', 'none');
      $(".rename-lable").css('display', 'none');
      $(".upload-lable").css('display', 'block');
      $(':checkbox').prop('checked', false);
      this.getMedia();
    }
      , (err) => {
      });
  }
  filter(value) {
    if (value == 'clear') {
      this.media = [] = this.actualData;
      $(':checkbox').prop('checked', false);
    }
    else {
      let $this = this;
      this.filterValue = [];
      $('.filter-data:checked').each(function () {
        $this.filterValue.push($(this).val());
      });
      var _tempMedia = [];
      if (this.filterValue.length <= 0) {
        this.media = [] = this.actualData;
      }
      else {
        this.filterValue.forEach(function (item) {
          $this.actualData.forEach(function (data) {
            if (item == 'pdf') {
              if (data.filepath.split(".")[1] == item) {
                if (_tempMedia.filter(a => a.id == data.id).length <= 0)
                  _tempMedia.push(data);
              }
            } else if (item == 'excel') {
              let excel = ['xlx', 'xlsx',];
              if (excel.includes(data.filepath.split(".")[1].toLowerCase())) {
                if (_tempMedia.filter(a => a.id == data.id).length <= 0)
                  _tempMedia.push(data);
              }
            }
            else if (item == 'image') {
              let image = ['png', 'jpeg', 'jpg', 'webp'];
              if (image.includes(data.filepath.split(".")[1].toLowerCase())) {
                if (_tempMedia.filter(a => a.id == data.id).length <= 0)
                  _tempMedia.push(data);
              }
            }

          })

        });
        this.media = [] = _tempMedia;
      }
    }
   
  }
  sort(value) {
      this.media.sort(function (a, b) {
        var nameA = a[value].toLowerCase(), nameB = b[value].toLowerCase()
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0 //default return value (no sorting)
      });
    
  }

  openModel() {
   this.mediaId=$('input:checked').val();
    this.renameValue = this.media.filter(x => x.id == this.mediaId)[0].filename;
    $("#myModal").css("display", "block");
    $(".modal-backdrop .in").css("opacity", "0");
    $(".modal - backdrop").css("z-index", "0");
  }
  rename() {
    let formData = new FormData();
    formData.append('filename', this.renameValue);
    formData.append('id', this.mediaId);
    let url = this.apiUrl + 'media/renamefile/' + this.mediaId;
    this.http.post(url, formData)
      .subscribe((data: any) => {
        $(".files-lable").css('display', 'none');
        $(".rename-lable").css('display', 'none');
        $(".upload-lable").css('display', 'block');
        $(':checkbox').prop('checked', false);
        this.closeModal()
        this.getMedia();
      });
  }
  download() {
    this.mediaId = $('input:checked').val();
    let fileUrl = this.media.filter(x => x.id == this.mediaId)[0].filepath;
    /*window.location.assign(this.imageurl + fileUrl)*/
    window.open(this.imageurl+fileUrl, '_blank');
  }
  copy(filepath) {
    debugger;
    let displayUrl = this.imageurl + filepath;
    var input_ = $("<input>");
    $('body').append(input_);
    input_.val(displayUrl).select();
    document.execCommand("copy");
    input_.remove();
    alert("Url copied!")
  }
  fileChangeEvent(event: any): void {
    let formData = new FormData();
    if (event.target.files.length > 0) {
      for (var i = 0; i < event.target.files.length; i++) {
        formData.append('file', event.target.files[i]);
      }
    }
    let url = this.apiUrl + 'media/upload';
    this.http.post(url, formData)
      .subscribe((data: any) => {
        this.getMedia();

      });
  }
  closeModal() {
    $("#myModal").css("display", "none");
  }
  clearSelection() {
    $(".files-lable").css('display', 'none');
    $(".rename-lable").css('display', 'none');
    $(".upload-lable").css('display', 'block');
    $(':checkbox').prop('checked', false);
  }

}
