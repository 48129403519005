import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionService } from './services/permission.service';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  APIURL: any;
  EXTERNALURL: any;
  loadingMessage: string;
  title = 'User Management';
  currentUser: any = {};
  permissions: any = [];
  isLoginPage: boolean = false;
    IMAGEURL: any;

  constructor(private spinner: NgxSpinnerService,private router: Router,
    private permissionsService: PermissionService) {
    this.APIURL = window['APIURL'];
    this.IMAGEURL = window['ImageUrl'];
    this.EXTERNALURL = window['EXTERNALURL'];
  }
  ngOnInit() {
    this.loadingMessage = "loading";
    var user = sessionStorage.getItem("_currentUser") || null;

    if (user != null)
      this.currentUser = JSON.parse(user);
    else
      this.currentUser = null;
    this.permissions = this.permissionsService.getSetPermissions();
    if ($('.content-wrapper').css("margin-left") == "0") {
      $('.content-wrapper').css("margin-left", "0");
    }
    this.startSpinnerWithTime();
  }
  showNotification(from, align, errMsg, colors) {
    const type = ['', colors];

    var color = Math.floor((Math.random() * 1) + 1);
    $.notify({
      message: errMsg
    }, {
      type: type[color],
      timer: 1000,
      placement: {
        from: from,
        align: align
      }
    });
  }
  startSpinner() {
    this.spinner.show();
  }
  stopSpinner() {
    this.spinner.hide();
  }
  startSpinnerWithTime() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }
}
