
<div class="content-wrapper" *ngIf="masterType=='menu'">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <h1 class="w-100">
            All Menus
            <a class="btn btn-primary float-right text-white" *ngIf="!hideShow && (userPermissions.includes('createmenu') || userRoles.includes('SibinAdmin'))"
               (click)="create('/master-configuration/menu/create')" >
            <i class="fas fa-plus fa-plus-sm mr-1"></i>Create Menu</a>
            <a *ngIf="archiveCount>0 && !hideShow && (userPermissions.includes('deletemenu') || userRoles.includes('SibinAdmin'))"
               class=" float-right mr-2 mt-2 text-danger cursor-pointer"
               (click)="ArchivedRecords('menu')" style="font-size:15px">
              <i class="nav-icon fas fa-trash "></i>
              Archived Records
            </a>
            <a *ngIf="hideShow" class="float-right mr-2 mt-2 cursor-pointer" (click)="Back('menu')" style="font-size:15px">
              <i class="nav-icon fas fa-arrow-left "></i>
              Back
            </a>
          </h1>
         
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="row" style="height: 475px;">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-tools float-left">
                <span>
                  <strong style="font-size: 14px;">Please drag and drop if you want to change the listing order.</strong>
                </span>
              </div>
              <div class="card-tools">
                <div class="input-group input-group" style="width: 350px;">
                  <input type="text" [(ngModel)]="Search" class="form-control float-right" placeholder="Search" (ngModelChange)="paginationPageNumber = 1">

                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                  </div>
                </div>
              </div>


            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-head-fixed" cdkDropList (cdkDropListDropped)="dropList($event)">
                <thead>
                  <tr>
                    <th (click)="sort('title')" style="width: 11rem;">
                      Name
                      <span *ngIf="column !='title'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='title' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='title' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>
                    <th (click)="sort('link')" style="width: 10rem;">
                      Link
                      <span *ngIf="column !='link'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='link' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='link' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>
                    <th (click)="sort('displayonhome')" class="text-center">
                      Display on Home
                      <span *ngIf="column !='displayonhome'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='displayonhome' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='displayonhome' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>

                    <th (click)="sort('listingOrder')">
                      Listing Order
                      <span *ngIf="column !='listingOrder'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='listingOrder' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='listingOrder' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>
                    <th class="text-center" (click)="sort('status')">
                      Status
                      <span *ngIf="column !='status'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='status' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='status' && !isDesc"><i class="fas fa-sort-down"></i></span>
                    </th>
                    <th (click)="sort('parentTitle')" style="width: 11rem;">
                      Parent Title
                      <span *ngIf="column !='parentTitle'"><i class="fa fa-sort-desc"></i></span>
                      <span *ngIf="column =='parentTitle' && isDesc"><i class="fa fa-sort-asc"></i></span>
                      <span *ngIf="column =='parentTitle' && !isDesc"><i class="fa fa-sort-desc"></i></span>

                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="( menuList |  filter:Search) as result">
                  <tr *ngIf="result.length === 0">
                    <td colspan="6">Sorry no record found..</td>
                  </tr>
                  <tr *ngFor="let item of result| paginate: { itemsPerPage: itemsPerPage,
                                    currentPage: paginationPageNumber };let i=index" cdkDrag class="{{(!userPermissions.includes('editmenu') && !userRoles.includes('SibinAdmin'))?null:'example-box'}}"
                      [cdkDragDisabled]="!userPermissions.includes('editmenu') && !userRoles.includes('SibinAdmin')">
                    <td>{{item.title}}</td>
                    <td>{{item.link}}</td>
                    <td align="center">
                      <a class="{{item.displayonhome=='0'?'text-success':'text-danger'}} cursor-pointer" *ngIf="userPermissions.includes('editmenu') || userRoles.includes('SibinAdmin')"
                         (click)="id=item.id;displayonhome=item.displayonhome" data-toggle="modal" data-target="#showhideModal" title="Activate/Deactivate">
                        <i class="fa {{item.displayonhome=='0'?'fa-times':'fa-check'}}"></i>
                      </a>
                      <a class="{{item.displayonhome=='0'?'text-success':'text-danger'}} not-allowed"
                         *ngIf="!userPermissions.includes('editmenu') && !userRoles.includes('SibinAdmin')" title="You do not have permission to edit.">
                        <i class="fa {{item.displayonhome=='0'?'fa-times':'fa-check'}}"></i>
                      </a>
                    </td>
                    <td id="{{i}}">{{item.listingOrder}}</td>
                    <td >
                      <a class="{{item.status=='Active'?'text-success':'text-danger'}} cursor-pointer"  *ngIf="userPermissions.includes('editmenu') || userRoles.includes('SibinAdmin')"
                         (click)="id=item.id;status=item.status" data-toggle="modal" data-target="#activeModal" title="Activate/Deactivate">
                        <i class="{{item.status=='Active'?'fas fa-unlock':'fas fa-lock'}}"></i>
                      </a>
                      <a class="{{item.status=='Active'?'text-success':'text-danger'}} not-allowed"
                         *ngIf="!userPermissions.includes('editmenu') && !userRoles.includes('SibinAdmin')" title="You do not have permission to edit.">
                        <i class="{{item.status=='Active'?'fas fa-unlock':'fas fa-lock'}}"></i>
                      </a>
                    </td>
                    <td>{{item.parentId=='0'?'--':item.parentTitle}}</td>
                    <td>
                      <a *ngIf="!hideShow && (userPermissions.includes('editmenu') || userRoles.includes('SibinAdmin'))" class="text-info cursor-pointer" (click)="edit(item)" title="Update">
                        <i class="fas fa-pencil-alt"> </i>
                        Edit
                      </a>
                      <a *ngIf="!hideShow && !userPermissions.includes('editmenu') && !userRoles.includes('SibinAdmin')" class="text-info not-allowed"
                         title="You do not have permission to edit.">
                        <i class="fas fa-pencil-alt"> </i>
                        Edit
                      </a>
                      <a *ngIf="!hideShow  && (userPermissions.includes('deletemenu') || userRoles.includes('SibinAdmin'))" class="text-danger cursor-pointer ml-2" (click)="id=item.id;status='Archive'" data-toggle="modal" data-target="#deleteModal" title="Archive">
                        <i class="fas fa-trash"></i>
                        Archive
                      </a>
                      <a *ngIf="!hideShow  && !userPermissions.includes('deletemenu') && !userRoles.includes('SibinAdmin')"
                         class="text-danger not-allowed ml-2" title="You do not have permission to delete.">
                        <i class="fas fa-trash"></i>
                        Archive
                      </a>
                      <a *ngIf="hideShow" class="text-danger cursor-pointer ml-2" (click)="id=item.id;status='InActive'" data-toggle="modal" data-target="#deleteModal" title="Archive">
                        <i class="fas fa-trash-restore"></i>
                        Unarchive
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div class="col-md-12 text-center mt-3">
                <div class="row">
                  <div align="right" class="col-md-4" style="margin-right: -20px;">
                    Page
                    <select style="width: 44px;" (change)="itemPerPagePagination($event)" id="pageItem">
                      <option value="5">5</option>
                      <option *ngIf="menuCount>5" value="10">10</option>
                      <option *ngIf="menuCount>10" value="25">25</option>
                      <option *ngIf="menuCount>25" value="50">50</option>
                      <option *ngIf="menuCount>50" value="75">75</option>
                    </select>
                  </div>
                  <div align="left" class="col-md-8">
                    <pagination-controls (pageChange)="paginationPageNumber = $event"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="content-wrapper" *ngIf="masterType=='category'">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <h1 class="w-100">
            All Categories
            <a class="btn btn-primary float-right text-white" *ngIf="!hideShow  && (userPermissions.includes('createcategory') || userRoles.includes('SibinAdmin'))"
               (click)="create('/master-configuration/category/create')"><i class="fas fa-plus fa-plus-sm mr-1"></i>Create Category</a>
            <a *ngIf="archiveCount>0 && !hideShow  && (userPermissions.includes('deletecategory') || userRoles.includes('SibinAdmin'))" class=" float-right mr-2 mt-2 text-danger cursor-pointer" (click)="ArchivedRecords('menu')" style="font-size:15px">
              <i class="nav-icon fas fa-trash "></i>
              Archived Records
            </a>
            <a *ngIf="hideShow" class="float-right mr-2 mt-2 cursor-pointer" (click)="Back('menu')" style="font-size:15px">
              <i class="nav-icon fas fa-arrow-left "></i>
              Back
            </a>
          </h1>

        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="row" style="height: 475px;">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-tools">
                <div class="input-group input-group" style="width: 350px;">
                  <input type="text" [(ngModel)]="Search" class="form-control float-right" placeholder="Search" (ngModelChange)="paginationPageNumber = 1">

                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                  </div>
                </div>
              </div>


            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-head-fixed ">
                <thead>
                  <tr>
                    <th (click)="sort('categoryName')">
                      Category
                      <span *ngIf="column !='categoryName'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='categoryName' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='categoryName' && !isDesc"><i class="fas fa-sort-down"></i></span>

                    </th>
                    <th (click)="sort('title')">
                      Menu
                      <span *ngIf="column !='title'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='title' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='title' && !isDesc"><i class="fas fa-sort-down"></i></span>
                    </th>
                    <th (click)="sort('status')">
                      Status
                      <span *ngIf="column !='status'"><i class="fas fa-sort-down"></i></span>
                      <span *ngIf="column =='status' && isDesc"><i class="fas fa-sort-up"></i></span>
                      <span *ngIf="column =='status' && !isDesc"><i class="fas fa-sort-down"></i></span>
                    </th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody *ngIf="( categoryList |  filter:Search) as result">
                  <tr *ngIf="result.length === 0">
                    <td colspan="4">Sorry no record found..</td>
                  </tr>
                  <tr *ngFor="let data of result | paginate: { itemsPerPage: itemsPerPage,
                                    currentPage: paginationPageNumber }; let i=index" [class.active]="i == selectedRow">
                    <td>{{data.categoryName}}</td>
                    <td>{{data.title}}</td>
                    <td>
                      <a class="{{data.status=='Active'?'text-success':'text-danger'}} cursor-pointer"   *ngIf="userPermissions.includes('editcategory') || userRoles.includes('SibinAdmin')" 
                         (click)="id=data.id;status=data.status" data-toggle="modal" data-target="#activeModal" title="Activate/Deactivate">
                        <i class="{{data.status=='Active'?'fas fa-unlock':'fas fa-lock'}}"></i>
                      </a>
                      <a class="{{data.status=='Active'?'text-success':'text-danger'}} not-allowed"
                         title="You do not have permission to edit." *ngIf="!userPermissions.includes('editcategory') && !userRoles.includes('SibinAdmin')" >
                        <i class="{{data.status=='Active'?'fas fa-unlock':'fas fa-lock'}}"></i>
                      </a>
                    </td>
                    <td>
                      <a *ngIf="!hideShow  && (userPermissions.includes('editcategory') || userRoles.includes('SibinAdmin'))"  class="text-info cursor-pointer" (click)="edit(data)" title="Update">
                        <i class="fas fa-pencil-alt"> </i>
                        Edit
                      </a>
                      <a *ngIf="!hideShow  && !userPermissions.includes('editcategory') && !userRoles.includes('SibinAdmin')"
                         class="text-info not-allowed" title="You do not have permission to edit.">
                        <i class="fas fa-pencil-alt"> </i>
                        Edit
                      </a>
                      <a *ngIf="!hideShow  && (userPermissions.includes('deletecategory') || userRoles.includes('SibinAdmin'))" class="text-danger cursor-pointer ml-2" (click)="id=data.id;status='Archive'" data-toggle="modal" data-target="#deleteModal" title="Archive">
                        <i class="fas fa-trash"></i>
                        Archive
                      </a>
                      <a *ngIf="!hideShow  && !userPermissions.includes('deletecategory') && !userRoles.includes('SibinAdmin')"
                         class="text-danger not-allowed ml-2"  title="You do not have permission to delete.">
                        <i class="fas fa-trash"></i>
                        Archive
                      </a>
                      <a *ngIf="hideShow" class="text-danger cursor-pointer ml-2" (click)="id=data.id;status='InActive'" data-toggle="modal" data-target="#deleteModal" title="Archive">
                        <i class="fas fa-trash-restore"></i>
                        Unarchive
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div class="col-md-12 text-center mt-3">
                <div class="row">
                  <div align="right" class="col-md-4" style="margin-right: -20px;">
                    Page
                    <select style="width: 44px;" (change)="itemPerPagePagination($event)" id="pageItem">
                      <option value="5">5</option>
                      <option *ngIf="categoryCount>5" value="10">10</option>
                      <option *ngIf="categoryCount>10" value="25">25</option>
                      <option *ngIf="categoryCount>25" value="50">50</option>
                      <option *ngIf="categoryCount>50" value="75">75</option>
                    </select>
                  </div>
                  <div align="left" class="col-md-8">
                    <pagination-controls (pageChange)="paginationPageNumber = $event"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="content-wrapper" *ngIf="masterType=='album'">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <h1 class="w-100">
            All Albums
            <a class="btn btn-primary float-right text-white" *ngIf="!hideShow && (userPermissions.includes('createalbum') || userRoles.includes('SibinAdmin'))"
               (click)="create('/master-configuration/album/create')"><i class="fas fa-plus fa-plus-sm mr-1"></i>Create Album</a>
            <a *ngIf="archiveCount>0 && !hideShow  && (userPermissions.includes('deletealbum') || userRoles.includes('SibinAdmin'))" class=" float-right mr-2 mt-2 text-danger cursor-pointer" (click)="ArchivedRecords('menu')" style="font-size:15px">
              <i class="nav-icon fas fa-trash "></i>
              Archived Records
            </a>
            <a *ngIf="hideShow" class="float-right mr-2 mt-2 cursor-pointer" (click)="Back('menu')" style="font-size:15px">
              <i class="nav-icon fas fa-arrow-left "></i>
              Back
              </a>
          </h1>

        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="row" style="height: 475px;">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <a class="mr-2 mt-2 cursor-pointer" (click)="backToCreate()" style="font-size:15px">
                <i class="nav-icon fas fa-arrow-left "></i>
                Back
              </a>
              <div class="card-tools">
                <div class="input-group input-group" style="width: 350px;">
                  <input type="text" [(ngModel)]="Search" class="form-control float-right" placeholder="Search" (ngModelChange)="paginationPageNumber = 1">

                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                  </div>
                </div>
              </div>


            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <div class="row" *ngIf="( albumList |  filter:Search) as result">
                  <p *ngIf="result.length === 0">Sorry no records found..</p>
                <div class="col-md-2" *ngFor="let data of result">
                  <img *ngIf="!data.coverimage" src="../../assets/img//folder-blue.png"  width="100" height="100" />
                  <img *ngIf="data.coverimage" src="{{data.coverimage.includes('http')?data.coverimage:imageurlMedium+data.coverimage}}" class="img-thumbnail" width="100" height="100" />
                  <p class="mb-0">
                    <i class="fas {{data.albumtype == 'video'?'fa-video':'fa-images'}} mr-1"></i>{{data.albumname}}
                  </p>
                  <p>
                    <a *ngIf="!hideShow  && (userPermissions.includes('editalbum') || userRoles.includes('SibinAdmin'))"  class="text-info cursor-pointer" (click)="edit(data)" title="Update">
                      <i class="fas fa-pencil-alt"> </i>
                    </a>
                    <a *ngIf="!hideShow  && !userPermissions.includes('editalbum') && !userRoles.includes('SibinAdmin')"
                       class="text-info not-allowed"  title="You do not have permission to edit.">
                      <i class="fas fa-pencil-alt"> </i>
                    </a>

                    <a *ngIf="!hideShow  && (userPermissions.includes('deletealbum') || userRoles.includes('SibinAdmin'))"
                       class="text-danger cursor-pointer ml-2" (click)="id=data.id;status='Archive'" data-toggle="modal" data-target="#deleteModal" title="Archive">
                      <i class="fas fa-trash"></i>
                      
                    </a>
                    <a *ngIf="!hideShow  && !userPermissions.includes('deletealbum') && !userRoles.includes('SibinAdmin')"
                       class="text-danger not-allowed ml-2" title="You do not have permission to delete.">
                      <i class="fas fa-trash"></i>
                      
                    </a>
                    <a *ngIf="hideShow" class="text-danger cursor-pointer ml-2" (click)="id=data.id;status='InActive'" data-toggle="modal" data-target="#deleteModal" title="Unarchive">
                      <i class="fas fa-trash-restore"></i>
                      Unarchive
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<div id="deleteModal" class="modal">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <a class="material-icons" id="cross">&times;</a>
        </div>
        <h4 class="modal-title w-100">Are you sure?</h4>
        <!--<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>-->
      </div>
      <div class="modal-body">
        <p>Do you really want to {{status=='Archive'?'Archive':'Unarchive'}} these records?</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="changeMenuStatus(id,status,'status')" data-dismiss="modal"> {{status=='Archive'?'Archive':'Unarchive'}} </button>
      </div>
    </div>
  </div>
</div>
<div id="activeModal" class="modal">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <a class="material-icons" id="cross">&times;</a>
        </div>
        <h4 class="modal-title w-100">Are you sure?</h4>
        <!--<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>-->
      </div>
      <div class="modal-body">
        <p>Do you really want to {{status=='Active'?'Deactivate':'Activate'}} these records?</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="changeMenuStatus(id,status,'status')" data-dismiss="modal">{{status=='Active'?'Deactivate':'Activate'}} </button>
      </div>
    </div>
  </div>
</div>

<div id="showhideModal" class="modal">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <a class="material-icons" id="cross">&times;</a>
        </div>
        <h4 class="modal-title w-100">Are you sure?</h4>
        <!--<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>-->
      </div>
      <div class="modal-body">
        <p>Do you really want to {{displayonhome=='0'?'Show':'Hide'}} these records?</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="changeMenuStatus(id,displayonhome,'displayonhome')" data-dismiss="modal">{{displayonhome=='0'?'Show':'Hide'}} </button>
      </div>
    </div>
  </div>
</div>
